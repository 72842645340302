/* eslint-disable max-len */
import { useContext } from 'react';
import {
  Grid, Pagination, CircularProgress, Typography,
} from '@mui/material';
import { Context, Provider } from './context';
import Table from '../../../components/Table';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';
import UserModal from './components/modal';

function Users() {
  const {
    loadingUsers,
    columns,
    users,
    totalPages,
    usersPage,
    fetchUsers,
    sortModel,
    setSortModel,
    searchText,
    setSearchText,
    handleKeyDown,
    searchClick,
    usersTotal,
    openModal,
    onModalClose,

  } = useContext(Context);
  
  console.log(users);

  return (
    <Grid sx={{ mt: 4 }} container spacing={2} className="geneology-list">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextInput
          size="sm"
          placeholder="What would you like to search for?"
          value={searchText}
          onChange={(e) => {
            console.log(e);
            setSearchText(e);
          }}
          onKeyDown={handleKeyDown}
          endAdornment={(
            <Button onClick={searchClick} className="py-auto my-auto mr-5" bordered size="sm">
              <span className="no-wrap">Search</span>
            </Button>
          )}
        />
      </Grid>

      {loadingUsers ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <Grid item container xs rowSpacing={2}>
          <Grid
            item
            container
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Table
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => { console.log(newSortModel); setSortModel(newSortModel); }}
              disableColumnFilter={false}
              loading={loadingUsers}
              columns={columns}
              rows={users}
              className="table mb-4"
              sortingOrder={['desc', 'asc']}
              sortingMode="server"
              getRowClassName={() => { }}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <Typography sx={{ mt: 0.5 }}>
              {`Total of: ${usersTotal} gamers`}
            </Typography>
            <Pagination
              count={totalPages}
              page={usersPage}
              onChange={(val, page) => {
                fetchUsers(page);
              }}

            />
          </Grid>
        </Grid>
      )}
      <UserModal selectedUser={openModal} handleUserModalClose={onModalClose} />
    </Grid>
  );
}

export default () => (
  <Provider>
    <Users />
  </Provider>
);
