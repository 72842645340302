import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

export default function RenderPassCol({ row }) {
  const { key, passType, Token } = row;
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Typography>
          {key || Token}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption" style={{ color: '#919191' }}>
          {passType?.name}
        </Typography>
      </Grid>
    </Grid>
  );
}
