import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function BuyForm({
  data, handleModalClose,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const buyFreePasses = () => {
    setLoading(true);
    handleModalClose(false);

    if (data?.fromSignup) {
      navigate('/dashboard/enroll-now/success');
      sessionStorage.setItem('paidSignupUser', sessionStorage.getItem('signupUser'));
      sessionStorage.removeItem('signupUser');
    }

    if (data?.fromProfile) {
      navigate('/profile');
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <div className="flex justify-center" style={{ position: 'absolute', left: '50%' }}>
          <CircularProgress />
        </div>
      )}

      <h1 style={{ textAlign: 'center' }}>{data?.formTitle}</h1>
      {data.priceTotal >= 1
        ? (
          <>
            <h3 style={{ textAlign: 'center' }}>{'This can\'t be reversed'}</h3>
            <form className="form" method="POST" action={process.env.REACT_APP_BTC_PAY}>
              <input type="hidden" name="orderId" value={data.PUID} />
              <input type="hidden" name="storeId" value={process.env.REACT_APP_BTC_STORE_ID} />
              <input type="hidden" name="checkoutDesc" value={data.Description} />
              <input type="hidden" name="serverIpn" value={data?.serverIpn} />
              <input type="hidden" name="browserRedirect" value={data?.redirect} />
              <input type="hidden" name="price" value={data.priceTotal} />
              <input type="hidden" name="currency" value="USD" />
              <button
                style={{ alignSelf: 'center', border: 'none' }}
                size="md"
                className="btn md default normal"
                type="submit"
                name="purchasePass"
              >
                {`Purchase ($${data.price})`}
              </button>
            </form>
          </>
        ) : (
          <div className="form">
            <button
              style={{
                alignSelf: 'center', border: 'none', opacity: `${loading ? 0.6 : 1}`, cursor: `${loading ? 'default' : 'pointer'}`,
              }}
              size="md"
              className="btn md default normal"
              type="submit"
              name="purchasePass"
              onClick={buyFreePasses}
              disabled={loading}
            >
              {`Purchase ($${data.price})`}
            </button>
          </div>
        )}
    </div>
  );
}
