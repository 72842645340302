import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../../../../components/CardLayout';

export default function PackageCard({ data, onPackageClick = () => { } }) {
  return (
    <Layout>
      <Typography
        sx={{
          fontWeight: 600,
          color: 'var(--primary-color)',
          fontSize: '16px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {data.name}
        <EditIcon
          sx={{ cursor: 'pointer' }}
          color="primary"
          onClick={() => {
            onPackageClick(data);
          }}
        />
      </Typography>
      <div className="divider" />
      <Typography sx={{ opacity: 0.5 }}>{data?.description || '-'}</Typography>
      <Typography>
        Pass Type :
        <span className="pl-2">{data?.passesType?.name}</span>
      </Typography>
      <Typography>
        Price :
        <span className="pl-2">
          $
          {Number(data.Price).toLocaleString('en-us')}
        </span>
      </Typography>
      <Typography>
        Nr. of Passes :
        <span className="pl-2">{data?.numberOfPasses || 0}</span>
      </Typography>
    </Layout>
  );
}
