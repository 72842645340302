import { useContext } from 'react';
import { AppContext } from '../../../utils/store/app';

const formConfig = [
  {
    name: 'name',
    isText: false,
    type: 'text',
    label: 'Package Name',
  },
  {
    name: 'passType',
    label: 'Pass Type',
    type: 'select',
    options: [],
  },
  {
    name: 'numberOfPasses',
    isText: false,
    type: 'number',
    label: 'Number Of Passes',
  },
  {
    name: 'Price',
    isText: false,
    type: 'money',
    label: 'Price',
  },
  {
    name: 'commissionAmount',
    isText: false,
    type: 'money',
    label: 'Commission Amount',
  },
  {
    name: 'description',
    isText: false,
    type: 'textarea',
    label: 'Description',
  },
];

export const getFormConfig = () => {
  const { passTypes } = useContext(AppContext);
  const opts = passTypes.filter((p) => p.active).map((val) => ({ label: val.name, value: val.id }));
  formConfig[1].options = opts;
  return formConfig;
};

export const defaultFormValues = {
  Package: '',
  Description: '',
  Price: 0,
  Points: '0',
  Days: '0',
  commissionAmount: 0,
};

export const formValidationConfig = {
  name: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  passType: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  numberOfPasses: {
    isEmpty: false,
    type: 'Number',
    message: 'Please do not leave this field empty!',
  },
};

export default formConfig;
