/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import PassTypes from '../../../../Authentication/PayNow/components/PassTypes';
import BuyButton from '../../../../Authentication/PayNow/components/BuyButton';
import Modal from '../../../../../components/Modal';
import { AppContext } from '../../../../../utils/store/app';
import BuyForm from '../../../../../components/BuyForm';
import useApi from '../../../../../utils/api';
// import StripePay from '../../../../Authentication/PayNow/components/StripePay';
// import RadioGroup from '../../../../../components/Inputs/RadioGroup';

export default function PaymentRenewModal({
  data,
  handleModalClose,
  selectedPackage,
  setSelectedPackage,
  // location = '',
}) {
  const { user } = data;
  const { t } = useTranslation();
  const { passTypes } = useContext(AppContext);
  const [loading, setLoading] = useState();
  const { authPost, authDelete } = useApi();
  const [buyFormData, setBuyFormData] = useState();
  // const [step, setStep] = useState(1);
  // const [clientSecret, setClientSecret] = useState();
  // const [paymentMethod, setPaymentMethod] = useState('creditCard');

  // const getClientSecret = async () => {
  //   try {
  //     const res = await authPost('/stripe/create-payment-intent', { data: { passTypeId: selectedPackage, user, membershipRenewal: true } });
  //     setClientSecret(res.client_secret);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const onBuy = async () => {
    // if (paymentMethod === 'creditCard') {
    //   if (selectedPackage) {
    //     getClientSecret();
    //   }
    //   setStep(2);
    // } else {
    setLoading(true);
    try {
      const result = await authPost('/payments/membership/start', {
        data: { SID: user.SID, passType: selectedPackage, amountOfPasses: 1 },
      });

      const serverIpn = `${process.env.REACT_APP_BASE_URL}/payments/renew/membership/ipn/${result.PUID}`;
      const uri = window.location.href;
      const uriParts = new URL(uri);
      const { origin } = uriParts;
      const redirect = `${origin}/profile`;
      // const redirect = `${process.env.REACT_APP_HOST}/profile`;
      setBuyFormData({
        ...result,
        formTitle: 'Proceed to checkout?',
        redirect,
        serverIpn,
        fromProfile: true,
      });
      sessionStorage.setItem('_puid', result.PUID);
    } catch (err) {
      console.log('[buy]', err);
      toast.error(t('We cannot renew your membership right now, please try again later!'));
    }

    setLoading(false);
    // }
  };

  const handleBuyModalClose = async (canceled = true) => {
    setLoading(true);
    try {
      if (canceled) {
        const _puid = sessionStorage.getItem('_puid');
        if (_puid) {
          await authDelete(`/payments/passes/remove/${_puid}`);
        }
      } else {
        await authPost('/payments/renew/deposit', { data: { ...buyFormData, SID: user.SID } });
        toast.success(t('You have succefully renewed your membership!'));
      }
    } catch (err) {
      console.log(err);
      toast.error(t('We cannot renew your membership right now, please try again later!'));
    }
    setBuyFormData(null);
    sessionStorage.removeItem('_puid');
    setLoading(false);
    handleModalClose();
  };

  // useEffect(() => {
  //  if (selectedPackage) {
  //    getClientSecret();
  //  }
  // }, [selectedPackage]);

  return (
    <Modal
      title={t('Purchase pass confirmation!')}
      width="30%"
      open={!!data}
      handleClose={handleModalClose}
    >
      {/* {
        step === 1 ? ( */}
      <Grid container spacing={2}>
        <PassTypes
          packages={passTypes.filter((p) => p.active)}
          loading={loading}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          formTitle="Select Pass Type"
        />

        {/* <RadioGroup
          onChange={(e) => { setPaymentMethod(e); }}
          setErrors={() => { }}
          value={paymentMethod}
          options={[{ value: 'creditCard', label: 'Credit Card' }, { value: 'crypto', label: 'Crypto' }]}
          sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', my: 3,
          }}
        /> */}

        <BuyButton disabled={loading} onSubmitBitcoin={onBuy} style={{ marginTop: '25px' }} />
      </Grid>
      {/* )

          : (
            <StripePay
              setStep={setStep}
              signupUser={user}
              location={location}
              clientSecret={clientSecret}
            />
          )
      } */}

      {buyFormData && (
        <Modal
          title={t('Renew Membership!')}
          width="25%"
          open={!!buyFormData}
          handleClose={handleBuyModalClose}
        >
          <BuyForm
            data={buyFormData}
            handleModalClose={handleBuyModalClose}
          />
        </Modal>
      )}
    </Modal>
  );
}
