import { Typography, Container, Grid } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useState } from 'react';
import Modal from '../../../../../../components/Modal';
import TextInput from '../../../../../../components/Inputs/TextInput';
import Button from '../../../../../../components/Button';

const titleType = {
  suspend: 'Enter Reason',
  'adjust-membership-date': ' Number of Days to Adjust',
  terminate: 'Are you sure you want to terminate this gamer?',
  'confirm-adjustment': 'Are you sure?',
};

const renderModals = ({
  type,
  open,
  onModalClose,
  data,
  setData,
  onSuspend,
  setSuspendText,
  terminate,
  adjustMembershipDate,
  adjustMembershipDateConfirmed,
  t,
}) => {
  const [reason, setReason] = useState(null);
  const [validation, setValidation] = useState(false);

  return (
    <Modal width="35%" title={titleType[type]} open={open} handleClose={onModalClose}>
      {type === 'suspend' && (
        <Container sx={{ pt: 0, mt: 0 }}>
          <TextInput
            placeholder="Reason for Suspension..."
            type="textarea"
            value={reason}
            onChange={(e) => {
              setReason(e);
              setValidation(false);
            }}
          />
          <Typography
            sx={{
              fontSize: '14px',
              color: 'brown',
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
              mt: 2,
            }}
          >
            {validation}
          </Typography>
          {' '}
          <Button
            onClick={() => {
              if (reason?.length > 0) {
                onSuspend({ ...data, reason });
                setSuspendText(data.suspended ? 'UNSUSPEND' : 'SUSPEND');
                onModalClose();
                setReason('');
              } else {
                setValidation(t('Please enter a reason!'));
              }
            }}
            className="mt-7"
          >
            {' '}
            Submit
          </Button>
          <Typography
            onClick={onModalClose}
            sx={{
              textDecoration: 'underline',
              display: 'flex',
              justifyContent: 'center',
              mt: 5,
              cursor: 'pointer',
            }}
          >
            {' '}
            Cancel
          </Typography>
        </Container>
      )}

      {type === 'cache-flushed' && (
        <Container
          sx={{
            pt: 0,
            mt: 0,
          }}
        >
          <CheckCircleOutlineIcon
            sx={{
              fontSize: '65px',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
            color="primary"
          />
          <Typography
            sx={{
              fontSize: '47px',
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {' '}
            Cache Flushed
          </Typography>
          <Button onClick={onModalClose} className="mt-7">
            {' '}
            OK
          </Button>
        </Container>
      )}

      {(type === 'success' || type === 'error') && (
        <Container
          sx={{
            pt: 0,
            mt: 0,
          }}
        >
          {type === 'error' ? (
            <HighlightOffIcon
              sx={{
                fontSize: '65px',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
              color="error"
            />
          ) : (
            <CheckCircleOutlineIcon
              sx={{
                fontSize: '65px',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
              color="primary"
            />
          )}
          <Typography
            sx={{
              fontSize: '47px',
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {data.title}
          </Typography>
          <Button onClick={onModalClose} className="mt-7">
            {data.buttonText || 'Ok'}
          </Button>
        </Container>
      )}

      {type === 'adjust-membership-date' && (
        <Container>
          <Grid
            container
            columnSpacing={2}
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xl={2} lg={3} md={3} sm={4} xs={4} className="py-auto my-auto">
              <Button
                bordered
                className="w-fit"
                onClick={() => {
                  if (data > 0) {
                    setData((i) => i - 1);
                    setValidation(false);
                  }
                }}
              >
                <span className="ml-1 mr-1 my-auto py-auto">-</span>
              </Button>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={4} xs={4} className="py-auto my-auto">
              <TextInput value={data} type="number" onChange={(e) => setData(e)} />
            </Grid>

            <Grid item xl={2} lg={3} md={3} sm={4} xs={4} className="py-auto my-auto">
              <Button
                className="w-fit"
                bordered
                onClick={() => {
                  setData((i) => i + 1);
                  setValidation(false);
                }}
              >
                <span className="ml-1 mr-1 my-auto py-auto">+</span>
              </Button>
            </Grid>
          </Grid>

          <Typography
            sx={{
              fontSize: '14px',
              color: 'brown',
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            {validation}
          </Typography>
          <Container sx={{ display: 'flex', justifyContent: 'space-between', gap: 3 }}>
            <Button
              className="flex-1"
              onClick={() => {
                if (data === 0) {
                  setValidation(t('Value must not be 0!'));
                } else adjustMembershipDate({ days: data, type: 'reduce' });
              }}
            >
              <span>Reduce</span>
            </Button>
            <Button
              className="flex-1"
              onClick={() => {
                if (data === 0) {
                  setValidation(t('Value must not be 0!'));
                } else adjustMembershipDate({ days: data, type: 'extend' });
              }}
            >
              <span>Extend</span>
            </Button>
          </Container>

          <Typography
            onClick={onModalClose}
            sx={{
              textDecoration: 'underline',
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
              cursor: 'pointer',
            }}
          >
            {' '}
            Cancel
          </Typography>
        </Container>
      )}

      {type === 'terminate' && (
        <Container sx={{ display: 'flex', justifyContent: 'space-between', gap: 3 }}>
          <Button
            className="flex-1"
            onClick={() => {
              onModalClose();
            }}
            bordered
          >
            <span>Cancel</span>
          </Button>
          <Button
            className="flex-1"
            onClick={() => {
              terminate();
            }}
          >
            <span>Terminate</span>
          </Button>
        </Container>
      )}

      {type === 'confirm-adjustment' && (
        <Container>
          {data.type === 'extend' && (
            <Typography>
              You want to 
              {' '}
              <u>extend</u>
              {' '}
              the membership date of this gamer by 
              {' '}
              <u> 
                {' '}
                {data.days}
                {' '}
                days
              </u>
              .
            </Typography>
          )}

          {data.type === 'reduce' && (
            <Typography>
              You want to 
              {' '}
              <u>reduce</u>
              {' '}
              the membership date of this gamer by 
              {' '}
              <u> 
                {' '}
                {data.days}
                {' '}
                days
              </u>
              .
            </Typography>
          )}

          <Container
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 3,
              mt: 4,
            }}
          >
            <Button
              className="flex-1"
              onClick={() => {
                onModalClose();
              }}
              bordered
            >
              <span>Cancel</span>
            </Button>
            <Button
              className="flex-1"
              onClick={() => {
                adjustMembershipDateConfirmed(data);
              }}
            >
              <span>Continue</span>
            </Button>
          </Container>
        </Container>
      )}
    </Modal>
  );
};

export default renderModals;
