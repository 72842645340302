import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function AuthLayout({ children, style }) {
  const { t } = useTranslation();

  return (
    <div className="layout-container">
      <div className="flex-1 flex-column pt-15 pb-15">
        <div className="layout-header flex-row justify-center">
          <img alt="logo-white" src="/assets/MemeGames-logo-fullwhite.png" width="56px" />
        </div>

        <Container className="flex-1 mt-12 mb-12 layout-card" style={style} maxWidth="sm">
          {children}
        </Container>

        <div className="mt-0 layout-footer">
          <p>{t('© 2024  MemeGames.ai .')}</p>
          <p>{t('All rights reserved.')}</p>
        </div>
      </div>
    </div>
  );
}
