import { useTranslation } from 'react-i18next';
import { getFormConfig } from './config';
import Form from '../../../../components/Form';
import usePurchasePackage from './hook';
// import StripeModal from './components/stripeModal'; // stripe
import BtcModal from './components/modal';

export default function PurchasePackages({ fetchPasses }) {
  const { t } = useTranslation();
  const {
    submitPurchase, errors, setErrors, purchaseData, handleModalClose, defaultValue,
  } = usePurchasePackage(fetchPasses);

  return (
    <div>
      <h2 className="mb-4">{t('Purchase Packages')}</h2>

      <Form
        size="sm"
        config={getFormConfig()}
        onSubmit={submitPurchase}
        submitLabel="Purchase"
        defaultFormValue={defaultValue}
        errors={errors}
        setErrors={setErrors}
        buttonType="submit"
      />
      {purchaseData && (
        <BtcModal
          errors={errors}
          submit=""
          data={purchaseData}
          handleModalClose={handleModalClose}
        />
      )}
    </div>
  );
}
