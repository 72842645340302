const packageFormConfig = [
  {
    name: 'name',
    isText: false,
    type: 'text',
    label: 'Pass Type Name',
  },
  {
    name: 'description',
    isText: false,
    type: 'textarea',
    label: 'Description',
  },
  {
    name: 'price',
    isText: false,
    type: 'money',
    label: 'Price',
  },
  {
    name: 'points',
    isText: false,
    type: 'number',
    label: 'Points',
  },
  {
    name: 'days',
    isText: false,
    type: 'number',
    label: 'Days',
  },
  {
    name: 'commissionAmount',
    isText: false,
    type: 'money',
    label: 'Commission Amount',
  },
  {
    name: 'maxPurchaseUnits',
    isText: false,
    type: 'number',
    label: 'Maximum purchase units',
  },
  {
    name: 'active',
    label: 'Active',
    type: 'boolean',
    checked: true,
    formGroupType: ' row-reverse',
    labelClassName: 'pt-3',
  },
];

export const defaultFormValues = {
  name: '',
  description: '',
  price: 0,
  points: '0',
  days: '30',
  commissionAmount: 0,
  maxPurchaseUnits: '0',
  active: true,
};

export const formValidationConfig = {
  name: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  description: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  days: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
};

export const passTypesColors = [
  'green',
  'platinium',
  'gold',
  'sky',
  'rose',
  'purple',
  'blue',
  'orange',
  'red',
];

export default packageFormConfig;
