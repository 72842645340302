import Modal from '../../../../components/Modal';
import { getFormConfig } from '../config';
import Form from '../../../../components/Form';

export default function PackageModal({
  errors, data, handleModalClose, submit, setError,
}) {
  return (
    <Modal
      title={data?._id ? 'Edit Package' : 'Create Package'}
      width="35%"
      open={!!data}
      handleClose={handleModalClose}
    >
      <Form
        buttonType="button"
        errors={errors}
        setError={setError}
        defaultFormValue={data}
        config={getFormConfig()}
        size="sm"
        className=""
        submitLabel="Submit"
        onSubmit={submit}
        buttonStyle={{ minWidth: '182px' }}
      />
      {' '}
    </Modal>
  );
}
