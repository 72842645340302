/* eslint-disable no-unused-vars */
import {
  useContext, useState, useEffect, useCallback, 
} from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import getChangedData from '../../../../utils/data/getChangedData';
import useApi from '../../../../utils/api';
import { validateAll } from '../../../../components/Form/config';
import config, { formValidationConfig } from './config';

const requiredFields = ['Name', 'Surname', 'Address', 'Mobile', 'Email', 'DateBirth'];

export default function useProfile() {
  const { user, updateUser } = useContext(AuthenticationContext);
  const [errors, setErrors] = useState(null);
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();
  const [openAffiliateConfirmModal, setOpenAffiliateConfirmModal] = useState(false);
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();
  const { authPut } = useApi(); 
  const [formConfig, setFormConfig] = useState(config);

  const handleConfirmModalClose = () => {
    setOpenAffiliateConfirmModal(false);
  };

  const onConfirmationModalSubmit = (type) => {
    setOpenAffiliateConfirmModal(false);
  };

  useEffect(() => {
    const _user = typeof user === 'object' ? user : JSON.parse(user);
    setUserData({
      enrollmentLink: `${process.env.REACT_APP_HOST}/enroll/${_user.SID}`,
      ..._user,
    });
  }, [user]);

  const submitPersonalInformation = useCallback(
    async (data) => {
      try {
        const checkform = validateAll(data, formValidationConfig);
        if (checkform) {
          setErrors(checkform);
        } else {
          const _data = getChangedData(userData, data, requiredFields);
          const tmp = { ..._data };
          tmp.Username = data.Username;
          tmp.AddressLine1 = _data.Address.Line1;
          tmp.AddressLine2 = _data.Address.Line2;
          tmp.State = _data.Address.State;
          tmp.ZipCode = _data.Address.ZipCode;
          tmp.City = _data.Address.City;
          tmp.DateBirth = dayjs(_data.DateBirth).format('YYYY-MM-DD');
          tmp.Country = _data.Address.Country.ID;
          const res = await authPut('/profile/update-personal-info', { data: tmp });
          if (res) {
            setMessage({ type: 'success', label: 'Profile Updated Successfully!' });
          }
          updateUser(data);
          setErrors(null);
          toast.success(t('Profile Updated Successfully!'));
        }
      } catch (err) {
        console.log('=-err--=', err);
        (err || [])?.forEach((er) => {
          console.log(er);
          Object?.values(er)?.forEach((e) => {
            console.log('-----', e);
            e?.forEach((r) => {
              toast.error(r);
            });
          });
        });
        setErrors(err);
        setIsLoading(false);
      }
    },
    [userData],
  );

  useEffect(() => {
    setTimeout(() => {
      setMessage();
    }, 8000);
  }, [message]);

  useEffect(() => {
    if (formData?.isAffiliate && !userData?.isAffiliate) {
      setOpenAffiliateConfirmModal(true);
    }
  }, [formData?.isAffiliate, userData]);

  useEffect(() => {
    if (userData?.isAffiliate) {
      setFormConfig(formConfig.map((i) => {
        if (i.name === 'isAffiliate') {
          return {
            ...i,
            disabled: true,
            checked: true,
            active: true,
          };
        }
        return i;
      }));
    }
  }, [userData]);

  return {
    userData,
    submitPersonalInformation,
    isLoading,
    setIsLoading,
    errors,
    message,
    handleConfirmModalClose,
    openAffiliateConfirmModal,
    formData,
    setFormData,
    onConfirmationModalSubmit,
    t,
    formConfig,
  };
}
