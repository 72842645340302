import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Pagination } from '@mui/material';
import { columnsConfig } from './config';
import Table from '../../../../components/Table';
import { PAGE_SIZE } from '../../api';
import Select from '../../../../components/Inputs/Select';

export default function Transfers({
  data = [],
  loading,
  fetchTransfers,
  transferesPage,
  transferesTotal,
  setTransfersLoading,
}) {
  const { t } = useTranslation();
  const columns = useMemo(() => columnsConfig(t), [t]);
  const totalPages = useMemo(() => Math.ceil(transferesTotal / PAGE_SIZE), [transferesTotal]);
  const [filters, setFilters] = useState({ page: transferesPage });

  const handleChange = (selectedFilter) => {
    setTransfersLoading(true);
    if (selectedFilter !== 'all') {
      setFilters({ ...filters, direction: selectedFilter });
      fetchTransfers({ page: 1, direction: selectedFilter });
    } else {
      setFilters({ ...filters, direction: selectedFilter });
      fetchTransfers({ page: 1 });
    }
    setTransfersLoading(false);
  };

  return (
    <Grid container rowSpacing={2} className="transfers">
      <Grid item container xs={12}>
        <Grid item container xs={6} justifyContent="flex-start" />
        <Grid item container xs={6} justifyContent="flex-end" alignItems="center">
          <h3>
            {t('Filters')}
            :
          </h3>
          <Select
            className="transfrs-filter-select"
            placeholder="Direction"
            size="sm"
            value={filters?.direction || 'default'}
            onChange={(e) => handleChange(e)}
            options={[
              { value: 'all', label: 'All' },
              { value: 'sent', label: 'Sent' },
              { value: 'received', label: 'Received' },
            ]}
          />
        </Grid>
      </Grid>

      <Grid item container xs>
        {loading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <Grid item container xs rowSpacing={2}>
            <Grid
              item
              container
              xs={12}
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              <Table loading={loading} columns={columns} rows={data} className="table mb-4" />
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end">
              <Pagination
                count={totalPages}
                page={transferesPage}
                onChange={(val, page) => {
                  fetchTransfers({ ...filters, page });
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
