import dayjs from 'dayjs';
import RenderPassCol from './components/RenderPassCol';

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: () => 'first-column',
  renderCell: ({ value }) => value,
};

export const columnsConfig = (t) => [
  {
    field: 'Token',
    headerName: t('Token'),
    width: 200,
    ...defaultConfig,
    renderCell: RenderPassCol,
  },
  {
    field: 'owner',
    width: 200,
    headerName: t('Purchaser'),
    ...defaultConfig,
    renderCell: ({ value }) => `${value?.Name} (${value?.Username})`,
  },
  {
    field: 'DateCreated',
    headerName: t('Date Created'),
    width: 120,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('DD MMM YYYY'),
  },
  {
    field: 'DateUsed',
    headerName: t('Date Used'),
    width: 120,
    ...defaultConfig,
    renderCell: ({ value }) => (value ? dayjs(value).format('DD MMM YYYY') : '--'),
  },
  {
    field: 'Used',
    headerName: t('Used'),
    width: 150,
    ...defaultConfig,
    renderCell: ({ value }) => (value ? 'Used for Renewal' : 'Not Used'),
  },
  {
    field: 'Transferable',
    headerName: t('Transferable'),
    width: 140,
    ...defaultConfig,
    renderCell: ({ value }) => (value ? 'Transferable' : 'Not Transferable'),
  },
];

export default columnsConfig;
