/* eslint-disable no-nested-ternary */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AppLoader from '../../../../../components/AppLoader';

function isExpiringSoon(expireDate) {
  // Parse the input date string to a Date object
  const expireDateTime = new Date(expireDate);
  
  // Get the current date and time
  const currentDateTime = new Date();
  
  // Calculate the difference in milliseconds
  const diffInMilliseconds = expireDateTime - currentDateTime;
  
  // Convert milliseconds to days
  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
  
  // Check if the difference is within the next 3 days
  return diffInDays >= 0 && diffInDays <= 3;
}

export default function CustomTree({
  loggedUser, users, passTypes, loadingUsers, 
}) {
  return (
    <Grid container>
          
      {
               users?.map((user, index) => (
                 
                 <Grid
                   rowGap={3}
                   xl={12}
                   lg={12}
                   md={12}
                   sm={12}
                   xs={12}
                   sx={{
                     display: 'flex', justifyContent: 'center', flexDirection: 'row', 
                   }}
                   key={index}
                   item
                 >
                
                   <div>
                     <div style={{
                       color: 'white',
                       minHeight: '50px',
                       width: '240px',
                       borderWidth: '1px',
                       borderRadius: '5px',
                       borderStyle: 'solid',
                       borderColor: !user.monetized ? 'red' : isExpiringSoon(user.dateExpiring) ? 'yellow' : loggedUser.Username === user.username ? '#ffbf42' : user.monetized ? passTypes.find((i) => user?.Package?.toLowerCase() === i.name.toLowerCase())
                         ? passTypes.find((i) => user.Package === i.name).color
                         : ''
                         : 'gray',
                       display: 'flex',
                       justifyContent: 'start',
                       alignItems: 'center',
                       textAlign: 'center',
                       padding: '5px',
                 
                     }}
                     >
                       <div style={{
                         minWidth: '20px',
                         display: 'flex',
                       }}
                       >
                         {
                        user.isAffiliate && user.isProduct ? <AttachMoneyIcon sx={{ fontSize: '40px', color: 'green', m: 'auto' }} /> : user.isAffiliate ? <PeopleIcon sx={{ fontSize: '40px', color: 'var(--primary-color)' }} /> : ''
                      }
                       </div>
                       
                       {
                        !user.monetized ? <PriorityHighIcon sx={{ mx: 1, color: 'red' }} /> : ''
                       }
                    
                       <div style={{
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         textAlign: 'center',
                         padding: '5px',
                         flexDirection: 'column',
                         width: '100%',
                       }}
                       >
                         <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>{user.username}</Typography>
                         {
                          user.username !== 'ej' ? (
                            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
                              {' '}
                              Sponsor:
                              <span style={{ marginLeft: '3px' }}>
                                {user?.enrolledParent?.username}
                              </span>
                          
                            </Typography>
                          ) : ''
                         }
                        
                       </div>

                     </div>
                  
                     {
                    users.length - 1 === index ? '' : (
                      <div style={{
                  
                        display: 'flex',
                        justifyContent: 'center',
                    
                      }}
                      >
                        <div style={{
                          height: '30px',
                          width: '1px',
                          color: 'white',
                          background: 'white',
                    
                        }}
                        />
                      </div>
                    )
                  }
                   </div>
                 </Grid>
               ))
            
          }
          
      {
            loadingUsers ? <AppLoader /> : ''
          }
          
    </Grid>
  );
}
