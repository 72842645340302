/* eslint-disable import/no-named-as-default */
import {
  Routes, Route, BrowserRouter, Navigate,
} from 'react-router-dom';
import Payments from '../features/Payments';
import PublicRoute from './publicRoute';
import ProtectedRoute from './protectedRoute';
import Login from '../features/Authentication/Login';
import SignUp from '../features/Authentication/SignUp';
import PayNow from '../features/Authentication/PayNow';
import GetParent from '../features/Authentication/GetParent';
import LoginAdmin from '../features/Authentication/LoginAdmin';
import SignupSuccess from '../features/Authentication/SignupSucces';
import ThankYouPage from '../features/Authentication/ThankYou';
import UserExists from '../features/Authentication/UserExists';
import SignupForm from '../features/Authentication/SignupForm';
import VerifyEmailConfirm from '../features/Authentication/VerifyEmail';
import TokenExpired from '../features/Authentication/TokenExpired';
import PaymentStatus from '../features/Authentication/PaymentStatus';
import LandingPage from '../features/LandingPage';
import AppLayout from '../components/AppLayout';
import Home from '../features/Home';
import Profile from '../features/Profile';
import AdminRoutes from './adminRoutes';
import AdminMembers from '../features/Admin/Members';
import AdminPasses from '../features/Admin/Passes';
import Ranks from '../features/Admin/Ranks';
import PassTypes from '../features/Admin/PassTypes';
import Packages from '../features/Admin/Packages';
import LoginForm from '../features/Authentication/LoginEmail';
import AffiliateRoute from './affiliateRoutes';
// import Wallets from '../features/Wallets';
import Memberships from '../features/Memberships';
import { useAuthentication } from '../utils/store';
import Tree from '../features/Sales/Genealogy';
import List from '../features/Sales/List';
import ResetPassword from '../features/Authentication/NewPassword';
import ForgotPassword from '../features/Authentication/ForgotPassword';

export default function Navigation() {
  const { user } = useAuthentication();
  return (
    <BrowserRouter basename={process.env.REACT_APP_URL_BASE_NAME || ''}>
      <Routes>

        <Route path="/*" element={<Navigate to="/onboarding" />} />
        <Route path="/" element={<Navigate to="/onboarding" />} />

        <Route
          path="/onboarding"
          element={(
            <PublicRoute>
              <LandingPage />
            </PublicRoute>
          )}
        />

        <Route
          path="/"
          element={
            user ? (
              <ProtectedRoute>
                <AppLayout>
                  <Home />
                </AppLayout>
              </ProtectedRoute>
            ) : (
              <PublicRoute>
                <LandingPage />
              </PublicRoute>
            )
          }
        />

        <Route
          path="/dashboard/signin"
          element={(
            <PublicRoute>
              <Login />
            </PublicRoute>
          )}

        />

        <Route
          path="/login"
          element={(
            <PublicRoute>
              <LoginForm />
            </PublicRoute>
          )}
        />

        <Route
          path="/payment-status"
          element={(
            <PublicRoute>
              <PaymentStatus />
            </PublicRoute>
          )}
        />
        <Route
          path="/verify-email"
          element={(
            <PublicRoute>
              <VerifyEmailConfirm />
            </PublicRoute>
          )}
        />
        
        <Route
          path="/reset-password"
          element={(
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          )}
        />
        
        <Route
          path="/new-password"
          element={(
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          )}
        />

        <Route
          path="/token-expired"
          element={(
            <PublicRoute>
              <TokenExpired />
            </PublicRoute>
          )}
        />

        <Route
          path="/signup/form"
          element={(
            <PublicRoute>
              <SignupForm />
            </PublicRoute>
          )}
        />

        <Route
          path="/thank-you"
          element={(
            <PublicRoute>
              <ThankYouPage />
            </PublicRoute>
          )}
        />

        <Route
          path="/user-exists"
          element={(
            <PublicRoute>
              <UserExists />
            </PublicRoute>
          )}
        />

        <Route
          path="/dashboard/registration/:step"
          element={(
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          )}
        />

        <Route
          path="/dashboard/enroll-now/pay"
          element={(
            <PublicRoute>
              <PayNow />
            </PublicRoute>
          )}
        />

        <Route
          path="/renew-account"
          element={(
            <ProtectedRoute>
              <PayNow />
            </ProtectedRoute>
          )}
        />

        <Route
          path="/dashboard/enroll-now/success"
          element={(
            <PublicRoute>
              <SignupSuccess />
            </PublicRoute>
          )}
        />

        <Route
          path="/e/:username"
          element={(
            <PublicRoute>
              <GetParent />
            </PublicRoute>
          )}
        />

        <Route
          path="/admin/signin"
          element={(
            <PublicRoute>
              <LoginAdmin />
            </PublicRoute>
          )}
        />

        <Route path="/payments/final" element={<Payments />} />

        <Route
          path="/"
          element={(
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          )}
        >

          <Route path="/home" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/payment-success" element={<PaymentSuccess />} /> */}

          {/* <Route
            path="/wallets"
            element={(
              <AffiliateRoute isAffiliate={false} isProduct>
                <Wallets />
              </AffiliateRoute>
            )}
          /> */}
          <Route
            path="/memberships"
            element={(
              <AffiliateRoute isAffiliate={false} isProduct>
                <Memberships />
              </AffiliateRoute>
            )}
          />

          <Route
            path="/sales"
            element={(
              <AffiliateRoute isProduct isAffiliate={false}>
                <List />
              </AffiliateRoute>
            )}
          />
          
          <Route
            path="/sales/tree"
            element={(
              <AffiliateRoute isProduct isAffiliate={false}>
                <Tree />
              </AffiliateRoute>
            )}
          />
          
          <Route
            path="/sales/list"
            element={(
              <AffiliateRoute isProduct isAffiliate={false}>
                <List />
              </AffiliateRoute>
            )}
          />
          <Route element={<AdminRoutes />}>
            <Route path="/admin/members" element={<AdminMembers />} />
            <Route path="/admin/passes" element={<AdminPasses />} />
            <Route path="/admin/ranks" element={<Ranks />} />
            <Route path="/admin/pass-types" element={<PassTypes />} />
            <Route path="/admin/packages" element={<Packages />} />
            <Route path="/admin/tree" element={<Tree />} />

          </Route>

          <Route path="/admin/members/:id" element={<AdminMembers />} />
        </Route>
      </Routes>

    </BrowserRouter>
  );
}
