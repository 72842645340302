import {
  Grid, Tooltip, Typography,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  CategoryScale, Chart as ChartJS, LinearScale, PointElement, LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useContext, useMemo } from 'react';
import { Context } from '../../context';
import Button from '../../../../components/Button';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

export default function VoteCoin({ coin, isYesterday, isVote }) {
  const { onChooseMemeCoin, chosenMemeCoin } = useContext(Context);

  const chartData = useMemo(() => ({
    labels: (coin?.data?.market_data?.sparkline_7d?.price || []).map(() => ''),
    datasets: [
      {
        label: 'Second dataset',
        data: coin?.data?.market_data?.sparkline_7d?.price || [],
        fill: false,
        pointRadius: 0,
        borderColor: '#D8A878',
      },
    ],
  }), [coin]);

  const numbersData = useMemo(() => ({
    marketCap: Number(coin?.data?.market_data?.market_cap?.usd || 0).toLocaleString('en-us'),
    currentPrice: Number(coin?.data?.market_data?.current_price?.usd || 0).toLocaleString('en-us'),
    totalVolume: Number(coin?.data?.market_data?.total_volume?.usd || 0).toLocaleString('en-us'),
    priceChangePercentage24h:
      Number(coin?.data?.market_data?.price_change_percentage_24h || 0).toFixed(1),
    twitterScreen: coin?.data?.links?.twitter_screen_name,
  }), [coin]);

  const backgroundColor = useMemo(() => {
    if (isYesterday) {
      // eslint-disable-next-line no-nested-ternary
      return coin.winner ? 'rgba(128, 221, 187, 0.2)' : coin?.loser ? 'rgba(77, 38, 38, 0.2)' : '';
    }

    if (isVote) {
      return null;
    }

    return chosenMemeCoin?.id === coin?.id ? 'rgba(135, 102, 24, 0.2)' : '';
  }, [coin, chosenMemeCoin]);

  const isGrowing = useMemo(() => numbersData.priceChangePercentage24h > 0, [numbersData]);

  return (
    <Grid
      xl={6}
      lg={6}
      md={6}
      sm={6}
      xs={12}
      className="p-1 pt-2 pb-2 flex-1"
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        flexDirection: 'column',
        minHeight: '100px',
        height: '100%',
        backgroundColor,
      }}
    >
      <Tooltip title={coin.name}>
        <img className="mb-2" height={coin.height || '100px'} alt={`${coin.name}`} src={coin.image} />
      </Tooltip>

      {!isVote ? (
        <div className="flex flex-column" style={{ maxWidth: '70%', width: '70%', overflow: 'hidden' }}>
          <Line
            data={chartData}
            width="80%"
            height={50}
            options={{
              maintainAspectRatio: false,
              resizeDelay: 0,
              responsive: true,
              scales: {
                y: { ticks: { color: 'white' } },
              },
            }}
          />
        </div>
      ) : null}

      <div className="mb-2" style={{ width: '90%' }}>
        <Typography component="p" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          CurrentPrice:
          {' '}
          <strong>
            {numbersData.currentPrice}
            $
          </strong>
        </Typography>

        {!isVote ? (
          <Typography component="p" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            24h Volume:
            {' '}
            <strong>
              {numbersData.totalVolume}
              $
            </strong>
          </Typography>
        ) : null}

        {!isVote ? (
          <Typography component="p" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            MarketCap:
            {' '}
            <strong>
              {numbersData.marketCap}
              $
            </strong>
          </Typography>
        ) : null}

        {!isVote ? (
          <Typography component="p" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            % change today:
            {' '}
            <strong style={{ color: isGrowing ? '#37f812' : '#ff2400' }}>
              {numbersData.priceChangePercentage24h}
              %
              {isGrowing ? (
                <ArrowUpwardIcon style={{ width: '1rem', height: '1rem' }} />
              ) : (
                <ArrowDownwardIcon style={{ width: '1rem', height: '1rem' }} />
              )}
            </strong>
          </Typography>
        ) : null}

        {(isYesterday || isVote) ? (
          <Typography component="p" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Number of votes:
            {' '}
            <strong>{Number(coin.votes || 0).toLocaleString('en-us')}</strong>
          </Typography>
        ) : null}

        {(!(isYesterday || isVote) && !chosenMemeCoin) ? (
          <Button className="mt-2" onClick={() => onChooseMemeCoin(coin)}>
            Pick Winner
          </Button>
        ) : null}
      </div>

      {/* {numbersData.twitterScreen ? (
        <Timeline
          dataSource={{ sourceType: 'profile', screenName: numbersData.twitterScreen }}
          options={{ width: '250', height: '300', theme: 'dark' }}
        />
      ) : null} */}
    </Grid>
  );
}
