import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import Form from '../../../../../components/Form';
import { formConfig } from './config';
import ConfirmAffiliateModal from '../../../components/confirmAffiliateModal';

export default function Step2({
  onSubmit, errors, loading, data, setErrors, signupParent,
}) {
  const navigate = useNavigate();
  const [formConfiguration, setformConfiguration] = useState(formConfig);
  const { t } = useTranslation();
  const getFormConfig = () => formConfiguration;

  const [openAffiliateConfirmModal, setOpenAffiliateConfirmModal] = useState(false);
  const [currentData, setCurrentData] = useState(data);

  useEffect(() => {
    const [username, ...otherInp] = [...formConfiguration];
    setformConfiguration([{ ...username, subLabel: `( ${currentData?.username || 'Name'}.memegames.ai )` }, ...otherInp]);
  }, [currentData]);

  useEffect(() => {
    if (!signupParent) {
      navigate('/');
    }
  }, []);

  const handleConfirmModalClose = () => {
    setOpenAffiliateConfirmModal(false);
  };

  const onConfirmationModalSubmit = () => {
    handleConfirmModalClose();
  };

  useEffect(() => {
    if (currentData?.isAffiliate) {
      setOpenAffiliateConfirmModal(true);
    }
  }, [currentData?.isAffiliate]);

  return (
    <div className="signup-form" style={{ padding: '-10px' }}>
      <div className="admin-login">
        <div className="flex-row justify-center mb-12">
          <img alt="logo-black" src="/assets/logo_black.svg" />
        </div>

        {loading && (
          <div style={{ position: 'absolute', margin: '96px 165px', zIndex: '99' }}>
            <CircularProgress />
          </div>
        )}
        <Form
          size="md"
          config={getFormConfig(t)}
          submitLabel="Signup"
          onSubmit={onSubmit}
          defaultFormValue={data}
          errors={errors}
          formLoading={loading}
          setCurrentData={setCurrentData}
          buttonType="button"
          setErrors={setErrors}
        />
      </div>
      <ConfirmAffiliateModal
        openAffiliateConfirmModal={openAffiliateConfirmModal}
        setOpenAffiliateConfirmModal={setOpenAffiliateConfirmModal}
        t={t}
        handleConfirmModalClose={handleConfirmModalClose}
        onConfirmationModalSubmit={onConfirmationModalSubmit}
      />
    </div>
  );
}
