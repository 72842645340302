import { useState, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import CustomPopper from '../../../Popper';
import Icon from '../../../Icon';
import { AuthenticationContext } from '../../../../utils/store/authentication';

export default function UserPopper({ theme }) {
  const { signOut } = useContext(AuthenticationContext);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  return (
    <>
      <IconButton aria-describedby="id" color="inherit" onClick={handleClick}>
        <Icon name="User" width={25} height={25} color={theme.PRIMARY_COLOR} />
      </IconButton>
      <CustomPopper anchorEl={anchorEl} handleClick={handleClick} open={open} name="user-popper">
        <Typography onClick={signOut} sx={{ cursor: 'pointer' }} color="primary">
          LOG OUT
          {' '}
          <LogoutIcon
            sx={{ fontSize: '13px', ml: 2 }}
            className="my-auto py-auto"
            color="primary"
          />
          {' '}
        </Typography>
      </CustomPopper>
    </>
  );
}
