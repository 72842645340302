import dayjs from 'dayjs';
import RenderPassCol from '../Passes/components/RenderPassCol';

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',

  cellClassName: () => 'first-column',
  renderCell: ({ value }) => value,
};

export const columnsConfig = (t) => [
  {
    field: 'dirAction',
    headerName: t('Direction'),
    width: 120,
    ...defaultConfig,
    renderCell: ({ value }) => value,
  },
  {
    field: 'key',
    headerName: t('Pass'),
    width: 200,
    ...defaultConfig,
    renderCell: RenderPassCol,
  },
  {
    field: 'from',
    headerName: t('From User'),
    width: 200,
    ...defaultConfig,
    renderCell: ({ value }) => `${value?.name} (${value?.username})`,
  },
  {
    field: 'to',
    width: 200,
    headerName: t('To User'),
    ...defaultConfig,
    renderCell: ({ value }) => `${value?.name} (${value?.username})`,
  },
  {
    field: 'transferredAt',
    headerName: t('Transfer Date'),
    width: 200,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('DD MMM YYYY'),
  },
];

export default columnsConfig;
