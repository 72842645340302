import { Typography } from '@mui/material';
import dayjs from 'dayjs';

export default {};

export const colConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  cellClassName: () => 'first-column',
  renderCell: ({ value }) => value,
};

export const affiliateColumnsConfig = (t) => [
  {
    field: 'description',
    minWidth: 930,
    headerName: `${t('DESCRIPTION')}`,
    ...defaultConfig,
    renderCell: ({ value }) => `$${value}`,
  },
  {
    field: 'createdAt',
    headerName: t('DATE'),
    minWidth: 130,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('DD MMM YYYY'),
  },
];

export const eventsDummy = [
  {
    id: 0,
    date: '27 Feb 2024',
    title: 'Grand Opening at Affiliate World Conference Dubai!',
    description: 'Join us on center stage for our grand opening event taking place February 28 and 29 in Dubai!',
  },

];

export const rankRowsDummy = [
  {
    id: 0,
    rank: '',
    name: '',
  },
  {
    id: 1,
    rank: '',
    name: '',
  },
];

export const salesColumnsConfig = (t) => [
  {
    field: 'rank',
    headerName: '',
    width: 150,
    ...colConfig,
  },
  {
    field: 'name',
    headerName: t('NAME'),
    width: 150,
    ...colConfig,
  },

];

export const salesRowsDummy = [

];

export const rankColumnsConfig = (t) => [
  {
    field: 'name',
    headerName: t('NAME'),
    flex: 0.5,
    ...defaultConfig,

  },
  {
    field: 'rank',
    headerName: '',
    headerAlign: 'right',
    flex: 0.5,
    columnAlign: 'right',
    renderCell: ({ value }) => (
      <Typography sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
        {value}
      </Typography>
    ),
  },

];

export const rankProgressDummy = [
  {
    id: 0,
    type: 'PV',
    value: '0',
    valueNext: '450',
    valuePrev: '0',

  },
  {
    id: 1,
    type: 'TV',
    value: '0',
    valueNext: '0',
    valuePrev: '0',

  },
];

export const newsTabs = [

  {
    id: 0,
    name: 'Crypto News',
  },
  {
    id: 1,
    name: 'Tech News',
  },
  {
    id: 2,
    name: 'Real Estate News',
  },

];

export const cryptoSpanOptions = [
  {
    label: '24H', value: '24H',
  },
  {
    label: '1W', value: '1W',
  },
  {
    label: '1M', value: '1M',
  },
  {
    label: '1Y', value: '1Y',
  },
];

export const dummyNews = {
  0: [
    {
      id: 0,
      title: 'Crypto News 1',
      description: 'Crypto Description',
      url: '',
    },
    {
      id: 1,
      title: 'Crypto News 2',
      description: 'Crypto Description',
      url: '',

    },
    {
      id: 2,
      title: 'Crypto News 3',
      description: 'Crypto Description',
      url: '',

    },
  ],
  1: [
    {
      id: 0,
      title: 'Tech News 1',
      description: 'Tech Description',
      url: '',

    },
    {
      id: 1,
      title: 'Tech News 2',
      description: 'Tech Description',
      url: '',

    },
    {
      id: 2,
      title: 'Tech News 3',
      description: 'Tech Description',
      url: '',

    },
  ],
  2: [
    {
      id: 0,
      title: 'Real Estate News 1',
      description: 'Real Estate Description',
      url: '',

    },
    {
      id: 1,
      title: 'Real Estate News 2',
      description: 'Real Estate Description',
      url: '',

    },
    {
      id: 2,
      title: 'Real Estate News 3',
      description: 'Real Estate Description',
      url: '',

    },
  ],
};

export const aiExperts = [

  {
    id: 0,
    name: 'Matt the Tech Guy',
    descriptiom: 'Matt has been carefully trained by the TREC Global team to answer questions about foundational and emerging strategies for investing in Technology opportunities.',
    placeholder: 'What is momentum trading?',
  },
  {
    id: 1,
    name: 'Ms. Jones',
    descriptiom: 'Ms. Jones was trained by Real Estate Millionaires to answer questions on strategy for residential, commercial and industrial trends and insights.',
    placeholder: 'What is wholesaling?',
  },
  {
    id: 2,
    name: 'BTCharles',
    descriptiom: 'BTCharles (chuck for short) is trained as an expert on terminology, current trends, technology behind each coin, and current pricing.',
    placeholder: 'What is swing trading?',
  },

];
