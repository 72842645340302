import { createContext } from 'react';
import useHomeApi from './hook';

export const Context = createContext({
  rank: [],
  loadingCoins: false,
  rankColumns: [],
  rankProgression: [],
  user: {},
  events: [],
  eventsIndex: 1,
  setEventsIndex: () => { },
  eventChange: () => { },
  loadingUpdates: false,
  updateColumns: [],
  updatesData: [],
  totalPages: 0,
  updatesPage: 0,
  fetchUpdates: () => { },
  affiliateUpdatesColumns: [],
  t: () => { },
  sales: [],
  salesLoading: false,
  salesColumns: [],
  defaultWallet: {},
  coopWallet: {},
  onTrecGlobalClick: () => { },
  eventsLoading: false,
  volumes: [],
  volumesLoading: false,
  role: '',
  clickedAIButton: 0,
  setClickedAIButton: () => { },

  askAIModal: false,
  setAskAIModal: () => { },
  handleAskAIModalClose: () => { },
  setAiAnswer: () => { },
  aiAnswer: '',
  aiQuestion: '',
  setAiQuestion: () => { },
  aiAnswerLoading: false,
  setAiAnswerLoading: () => { },
  askAiSubmit: () => { },
  aiConversation: [],

  cryptoTimeSpan: '24H',
  setCryptoTimeSpan: () => { },

  newsTab: 0,
  changeNewsTab: () => { },
  changeNewsTabTab: () => { },
  newsTabTab: 0,
  news: [],

  expert: 0,
  changeExpert: () => { },

  showProduct: false,

  chosenMemeCoin: null,
  onChooseMemeCoin: () => { },
  setChosenMemeCoin: () => { },
  onVoteMemeCoin: () => { },
  memeCoinsList: [],
  openMemeConfirmModal: false,
  setOpenMemeConfirmModal: () => { },
});

export const Provider = ({ children }) => {
  const value = useHomeApi();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
