/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import dayjs from 'dayjs';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonRounded } from '@mui/icons-material';
import Button from '../../../../components/Button';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import PaymentRenewModal from './components/PaymentRenewModal';
import RenewMembershipModal from './components/RenewMembershipModal';
import useApi from '../../../../utils/api';

export default function Welcome() {
  const { user } = useContext(AuthenticationContext);
  const inputRef = useRef();
  const { t } = useTranslation();
  const { authGet } = useApi();
  const [renewPaymentData, setRenewPaymentData] = useState();
  const [renewMembershipData, setRenewMembershipData] = useState();
  const [selectedPackage, setSelectedPackage] = useState();

  const onRenew = async () => {
    try {
      // 1. Check if has passes available
      const availablePasses = await authGet('passes/available');
      // 2. Buy one with bitcoin
      if (availablePasses.length >= 1) {
        setRenewMembershipData({ user, availablePasses });
      } else {
        setRenewPaymentData({ user });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePaymentModalClose = () => {
    setRenewPaymentData();
  };

  const handleRenewModalClose = () => {
    setRenewMembershipData();
  };

  const onUserClick = () => {
    inputRef?.current?.click();
  };

  const onChangeImage = (e) => {
    console.log(e.target.files);
  };

  return (
    <div className="welcome-cont">
      <input onChange={onChangeImage} ref={inputRef} type="file" accept=".png, .jpg" hidden />

      <div onClick={onUserClick} className="icon-cont flex-row justify-center mb-6">
        <PersonRounded fontSize="large" />
      </div>
      <p className="mb-6">{t('click on the image above to change it.')}</p>
      <h5 className="mb-2">{t('Welcome')}</h5>
      <h4 className="mb-2">{user.Name}</h4>
      <p className="mb-6">
        {t('Member since:')}
        {dayjs(user.DateJoined).format('MMM DD, YYYY')}
      </p>
      {user?.isProduct && (
        <>
          <h5 className="mb-2">{t('Renewal Date:')}</h5>
          <h4 className="mb-4">{dayjs(user.DateLastRenewed).format('MMMM DD, YYYY')}</h4>
          <h5 className="mb-2">{t('Expiring Date:')}</h5>
          <h4 className="mb-4">{dayjs(user.DateExpiring).format('MMMM DD, YYYY')}</h4>
          <div className="flex-row justify-center">
            <Button onClick={onRenew} bordered size="sm">
              {t('Renew Now')}
            </Button>
          </div>

          {renewPaymentData && (
            <PaymentRenewModal
              handleModalClose={handlePaymentModalClose}
              data={renewPaymentData}
              selectedPackage={selectedPackage}
              setSelectedPackage={setSelectedPackage}
              location="profile"
            />
          )}

          {renewMembershipData && (
            <RenewMembershipModal
              handleModalClose={handleRenewModalClose}
              data={renewMembershipData}
              selectedPackage={selectedPackage}
              setSelectedPackage={setSelectedPackage}
            />
          )}
        </>
      )}

    </div>
  );
}
