export const formConfig = () => [
  {
    name: 'username',
    type: 'text',
    label: 'Website Name',
    placeholder: 'username', 
    subLabel: '( Name.memegames.ai )',
    format: (item) => item.trim(),
  },
  
  {
    name: 'country',
    label: 'Country',
    type: 'ajaxSelect',
    endPoint: '/enums/countries',
    mapper: (val) => ({ label: val.Name, value: val.ID }),
  },
  {
    name: 'mobile',
    label: 'Phone Number',
    type: 'phone',
    placeholder: 'Phone Number',
  },

  {
    name: 'isAffiliate',
    label: 'I also wish to be an ambassador.',
    type: 'muiCheckbox',
    formGroupType: ' row-reverse',
    labelClassName: 'pt-3',
  },
];

export const getFormConfig = () => formConfig;

export const formValidationConfig = {
  username: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  country: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' }, 
  mobile: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
};

// export default getFormConfig;
