const config = [
  {
    name: 'pass',
    type: 'text',
    placeholder: 'Pass',
    label: 'Use a Pass',
  },
];

export default config;
