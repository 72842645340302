/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable import/no-named-as-default */
import { useTranslation } from 'react-i18next';
import {
  useContext, useState, useEffect, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import {
  affiliateColumnsConfig,
  rankColumnsConfig, salesColumnsConfig, newsTabs, dummyNews, aiExperts,
} from '../config';
import { AuthenticationContext } from '../../../utils/store/authentication';
import useApi from '../../../utils/api';

const PAGE_SIZE = 10;

const useHomeApi = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const [rank, setRank] = useState([]);
  const [volumes, setVolumes] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventsIndex, setEventsIndex] = useState(0);
  const [loadingUpdates, setLoadingUpdates] = useState(false);
  const [updatesData, setUpdatesData] = useState([]);
  const [updatesPage, setUpdatesPage] = useState(0);
  const [updatesTotal, setUpdatesTotal] = useState(0);
  const [salesLoading, setSalesLoading] = useState(false);
  const [volumesLoading, setVolumesLoading] = useState(false);
  const [defaultWallet, setDefaultWallet] = useState(0);
  const [coopWallet, setCoopWallet] = useState(0);
  const { authGet, authPost } = useApi();
  const [eventsLoading, setEventsLoading] = useState(false);
  const navigate = useNavigate();

  // #region Rank Progression & Volume
  useEffect(() => {
    const _rank = user?.rank ? Number(user.rank) : 0 || 0;
    setRank([
      {
        id: 0,
        name: 'Current Rank',
        rank: `${_rank} Star`,
      },
      {
        id: 1,
        name: 'Next Rank',
        rank: `${_rank + 1} Star`,
      },
    ]);
  }, [user]);

  const rankColumns = useMemo(() => rankColumnsConfig(t), [t]);

  const fetchSales = async () => {
    setSalesLoading(true);
    try {
      setSalesLoading(false);
    } catch (err) {
      console.log(err);
      setSalesLoading(false);
    }
  };

  const fetchVolume = async () => {
    setVolumesLoading(true);
    try {
      const res = await authGet('/dashboard/volumes');
      setVolumes([res]);
      setVolumesLoading(false);
    } catch (err) {
      console.log(err);
      setVolumesLoading(false);
    }
  };

  useEffect(() => {
    fetchVolume();
    fetchSales();
  }, []);

  const salesColumns = useMemo(() => salesColumnsConfig(t), [t]);

  // #endregion

  // #region wallets
  const formatWalletData = (_data) => {
    const _default = _data?.find((i) => i.type === 'default');
    const _investment = _data?.find((i) => i.type === 'investment');

    setDefaultWallet(_default || {});
    setCoopWallet(_investment || {});
  };

  const fetchWalletData = async () => {
    try {
      /// get whatever wallet data is needed
      const _data = await authGet('/wallets');
      formatWalletData(_data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchWalletData();
  }, []);

  // #endregion

  // #region Events

  const getEvents = async () => {
    setEventsLoading(true);
    try {
      const res = await authGet('/events');
      setEvents(res.map((i, index) => ({
        id: index,
        title: i.zapier_display_title,
        description: i.body,
        date: i.created_at,
      })));
    } catch (err) {
      console.log(err);
    }
    setEventsLoading(false);
  };

  const eventChange = (_nr) => {
    if (_nr === events.length) {
      setEventsIndex(0);
    } else if (_nr === -1) {
      setEventsIndex(events.length - 1);
    } else {
      setEventsIndex(_nr);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  // #endregion

  // #region Affiliate Updates
  const fetchUpdates = () => {
    try {
      setLoadingUpdates(true);
      setUpdatesData([]);
      setUpdatesPage(0);
      setUpdatesTotal(0);
    } catch (err) {
      console.log(err);
    }
  };

  const affiliateUpdatesColumns = useMemo(() => affiliateColumnsConfig(t), [t]);
  const totalPages = useMemo(() => Math.ceil(updatesTotal / PAGE_SIZE), [updatesTotal]);

  // #endregion

  // #region circle

  const onTrecGlobalClick = async () => {
    navigate('https://trec-global.circle.so/home');
  };

  // #endregion

  // #region AI

  const [clickedAIButton, setClickedAIButton] = useState(0);

  const [aiQuestion, setAiQuestion] = useState('');
  const [aiAnswer, setAiAnswer] = useState('');
  const [aiAnswerLoading, setAiAnswerLoading] = useState(false);
  const [aiConversation, setAiConversation] = useState([]);

  // const openai = new OpenAI({
  // apiKey: process.env.REACT_APP_CHATGPT_KEY, // This is the default and can be omitted
  // dangerouslyAllowBrowser: true,
  // apiKey: process.env.REACT_APP_CHATGPT_KEY,
  // });

  const [askAIModal, setAskAIModal] = useState(false);
  const handleAskAIModalClose = () => {
    setAskAIModal(false);
  };

  const askAiSubmit = async () => {
    if (!aiQuestion) {
      return;
    }

    setAiAnswerLoading(true);
    const answerTime = dayjs();
    const _id = aiConversation.length;
    const _newConversation = [...aiConversation, {
      id: _id,
      q: aiQuestion,
      a: '',
      state: 'loading',
      qTime: answerTime,
      aTime: dayjs(),
    }];

    setAiConversation(_newConversation);

    try {
      const res = await authPost('/openai/answer', { data: { question: aiQuestion } });
      setAiAnswer(res.answer);
      setAiConversation(_newConversation.map((i) => {
        if (i.id === _id) {
          return {
            ...i,
            a: res.answer,
            aTime: dayjs(),
            state: 'done',

          };
        }
        return i;
      }));

      setAiQuestion('');
      setAiAnswerLoading(false);
    } catch (error) {
      setAiAnswer('Error fetching response!');
      setAiAnswerLoading(false);
      console.error('Error fetching response:', error);
    }
  };

  // #endregion

  // #region NEws

  const [cryptoTimeSpan, setCryptoTimeSpan] = useState('24H');
  const [newsTab, setNewsTab] = useState(0);
  const [newsTabTab, setNewsTabTab] = useState(0);
  const [news, setNews] = useState(dummyNews);

  const getNews = async () => {
    try {
      const res = await authGet('/dashboard/news');
      console.log(res);

      setNews({
        0: res?.crypto?.data || [],
        1: res?.technology?.data || [],
        2: res?.realEstate?.data || [],
      });
      // setNews(dummyNews);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  const changeNewsTab = (type) => {
    console.log(type);
    if (type === 'prev') {
      if (newsTab === 0) {
        setNewsTab(newsTabs.length - 1);
      } else {
        setNewsTab(newsTab - 1);
      }
    }
    if (type === 'next') {
      if (newsTab === newsTabs.length - 1) {
        setNewsTab(0);
      } else {
        setNewsTab(newsTab + 1);
      }
    }
    setNewsTabTab(0);
  };

  const changeNewsTabTab = (type) => {
    console.log(type);
    if (type === 'prev') {
      if (newsTabTab === 0) {
        setNewsTabTab(dummyNews[newsTab].length - 1);
      } else {
        setNewsTabTab(newsTabTab - 1);
      }
    }
    if (type === 'next') {
      if (newsTabTab === dummyNews[newsTab].length - 1) {
        setNewsTabTab(0);
      } else {
        setNewsTabTab(newsTabTab + 1);
      }
    }
  };
  // #endregion

  // #region experts

  const [expert, setExpert] = useState(0);

  const changeExpert = (type) => {
    if (type === 'prev') {
      if (expert === 0) {
        setExpert(aiExperts.length - 1);
      } else {
        setExpert(expert - 1);
      }
    }
    if (type === 'next') {
      if (expert === aiExperts.length - 1) {
        setExpert(0);
      } else {
        setExpert(expert + 1);
      }
    }
    // setExpert(0);
  };

  // #endregion

  // #region meme coins pool

  const [memeCoinsList, setMemeCoinsList] = useState([]);
  const [chosenMemeCoin, setChosenMemeCoin] = useState();
  const [loadingCoins, setLoadingCoins] = useState(false);
  const [openMemeConfirmModal, setOpenMemeConfirmModal] = useState();

  const getChosenMemecoin = async () => {
    try {
      const res = await authGet(`/memecoins/vote-history/${user.SID}`);
      console.log('histor', res);
      setChosenMemeCoin(res ? res[0] ? { id: res[0].memeCoinsId } : null : null || null);
    } catch (err) {
      console.log(err);
      setChosenMemeCoin(null);
    }
  };

  const getMemeCoin = async () => {
    try {
      setLoadingCoins(true);
      const res = await authGet('/memecoins/active');
      const _res = [];
      for (let i = 0; i < res.length; i += 1) {
        const coin = res[i];
        const otherCoin = i === 0 ? res[i + 1] : res[0];
        // eslint-disable-next-line no-await-in-loop
        const search = await authGet(`/search?query=${coin.name}`, { baseURL: process.env.REACT_APP_GECKO_URL });
        const geckoCoin = (search?.coins || [])[0];
        // eslint-disable-next-line no-await-in-loop
        const geckoRes = await authGet(`/coins/${geckoCoin?.id}?localization=false&tickers=false&market_data=true&community_data=true&developer_data=false&sparkline=true`, { baseURL: process.env.REACT_APP_GECKO_URL });
        _res.push({
          ...coin, data: geckoRes, winner: coin.votes > otherCoin.votes, loser: coin.votes < otherCoin.votes, draw: coin.votes === otherCoin.votes,
        });
      }
      setMemeCoinsList(_res);
    } catch (err) {
      console.log(err);
    }
    setLoadingCoins(false);
  };

  useEffect(() => {
    getMemeCoin();
    getChosenMemecoin();
  }, []);

  const onChooseMemeCoin = (chosen) => {
    setOpenMemeConfirmModal(chosen);
  };

  const onVoteMemeCoin = async () => {
    try {
      const res = await authPost('/memecoins/vote', { data: { memeCoinsId: openMemeConfirmModal.id } });
      setChosenMemeCoin(openMemeConfirmModal);
      setOpenMemeConfirmModal();
      getMemeCoin();
      toast.success(res.message);
    } catch (err) {
      console.log(err);
      toast.error(err || '');
      setOpenMemeConfirmModal();
    }
  };

  // #endregion

  return {
    rank,
    rankColumns,
    user,
    events,
    eventsIndex,
    setEventsIndex,
    eventChange,
    t,
    loadingUpdates,
    affiliateUpdatesColumns,
    updatesData,
    totalPages,
    updatesPage,
    fetchUpdates,
    salesLoading,
    salesColumns,
    defaultWallet,
    coopWallet,
    onTrecGlobalClick,
    eventsLoading,

    volumes,
    volumesLoading,

    clickedAIButton,
    setClickedAIButton,
    askAIModal,
    setAskAIModal,
    handleAskAIModalClose,
    setAiAnswer,
    aiAnswer,
    aiQuestion,
    setAiQuestion,
    aiAnswerLoading,
    setAiAnswerLoading,
    askAiSubmit,
    aiConversation,

    cryptoTimeSpan,
    setCryptoTimeSpan,

    newsTab,
    changeNewsTab,
    changeNewsTabTab,
    newsTabTab,
    news,

    changeExpert,
    expert,
    aiExperts,

    onChooseMemeCoin,
    chosenMemeCoin,
    setChosenMemeCoin,
    onVoteMemeCoin,
    memeCoinsList,
    openMemeConfirmModal,
    setOpenMemeConfirmModal,
    loadingCoins,

  };
};

export default useHomeApi;
