/* eslint-disable react/jsx-props-no-spreading */
import { Grid, Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import useMemberships from './hook';
import Passes from './components/Passes';
import Transfers from './components/Transfers';
import Layout from '../../components/CardLayout';
import PurchasePasses from './components/PurchasePasses';
import RenewMemberships from './components/RenewMemberships';
import CustomTabPanel, { a11yProps } from '../../components/Tabs';
import PurchasePackages from './components/PurchasePackages';
import PurchaseHistory from './components/PurchasesHistory';

export default function Memberships() {
  const {
    passes,
    passTypes,
    refresh,

    fetchPasses,
    passesTotal,
    passesTotalAvailable,
    passesPage,
    passesLoading,
    setPassesLoading,
    tabsValue,
    handleTabChange,

    transfers,
    setTransfersLoading,
    transfersLoading,
    fetchTransfers,
    transferesPage,
    transferesTotal,

    tabsHistoryValue,
    handleTabHistoryChange,

    purchaseHistoryLoading,
    PurchaseHistoryPage,
    purchaseHistoryTotal,
    purchaseHistory,
    fetchPurchaseHistory,
    setPurchaseHistoryLoading,
  } = useMemberships();

  const { t } = useTranslation();

  return (
    <Grid container className="memberships">
      <div className="col">
        <div>
          <Layout size="sm" maxWidth="xl" className="centered-card">
            <Passes
              fetchPasses={fetchPasses}
              fetchTransfers={fetchTransfers}
              passesTotal={passesTotal}
              passesTotalAvailable={passesTotalAvailable}
              setPassesLoading={setPassesLoading}
              passesPage={passesPage}
              data={passes}
              passTypes={passTypes}
              loading={passesLoading}
            />
          </Layout>
        </div>

        <div>
          <Layout size="sm" maxWidth="xl">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabsHistoryValue}
                onChange={handleTabHistoryChange}
                aria-label="basic tabs example"
              >
                <Tab label={t('Transfers History')} {...a11yProps(0)} />
                <Tab label={t('Purchases History')} {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabsHistoryValue} index={0}>
              <Transfers
                data={transfers}
                loading={transfersLoading}
                setTransfersLoading={setTransfersLoading}
                fetchTransfers={fetchTransfers}
                transferesPage={transferesPage}
                transferesTotal={transferesTotal}
              />
            </CustomTabPanel>

            <CustomTabPanel value={tabsHistoryValue} index={1}>
              <PurchaseHistory
                data={purchaseHistory}
                loading={purchaseHistoryLoading}
                setTransfersLoading={setPurchaseHistoryLoading}
                fetchTransfers={fetchPurchaseHistory}
                transferesPage={PurchaseHistoryPage}
                transferesTotal={purchaseHistoryTotal}
              />
            </CustomTabPanel>
          </Layout>
        </div>
      </div>

      <div className="col right-col">
        <div>
          <Layout size="sm" maxWidth="xl">
            <RenewMemberships refresh={refresh} />
          </Layout>
        </div>

        <div>
          <Layout size="sm" maxWidth="xl">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabsValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Passes" {...a11yProps(0)} />
                <Tab label="Packages" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabsValue} index={0}>
              <PurchasePasses fetchPasses={fetchPasses} />
            </CustomTabPanel>
            <CustomTabPanel value={tabsValue} index={1}>
              <PurchasePackages fetchPasses={fetchPasses} />
            </CustomTabPanel>
          </Layout>
        </div>
      </div>
    </Grid>
  );
}
