const config = [
  {
    name: 'myAccountTitle',
    isText: true,
    type: 'h6',
    label: 'My Account',
  },

  {
    name: 'enrollmentLink',
    type: 'text',
    label: 'Enrollment Link',
    disabled: true,
  },
  {
    name: 'Name',
    type: 'text',
    label: 'Name',
  },
  {
    name: 'Surname',
    type: 'text',
    label: 'Surname',
  },
  {
    name: 'Username',
    type: 'text',
    label: 'Username',
  },
  {
    name: 'SID',
    type: 'text',
    label: 'Member ID',
    disabled: true,
  },
  //  {
  //    name: 'EnrolledParentID',
  //    type: 'text',
  //    label: 'Enrolled Parent ID',
  //    disabled: true,
  //  },
  // 
  {
    name: 'isAffiliate',
    label: 'I wish to continue as an Ambassador only. I will not pay for a beta gamer pass and will not have access to the Beta Game buildout phase.',
    type: 'muiCheckbox',
    formGroupType: ' row-reverse',
    labelClassName: 'pt-3',
  },

  {
    name: 'addressInfoTitle',
    isText: true,
    type: 'h6',
    label: 'Address Info',
  },
  {
    name: 'Address.Line1',
    type: 'text',
    label: 'Address Line 1',
  },
  {
    name: 'Address.Line2',
    type: 'text',
    label: 'Address Line 2',
  },
  {
    name: 'Address.Country.Name',
    type: 'text',
    label: 'Country',
    disabled: true,
  },
  {
    name: 'Address.City',
    type: 'text',
    label: 'City',
  },
  {
    name: 'Address.State',
    type: 'text',
    label: 'State',
  },
  {
    name: 'Address.ZipCode',
    type: 'text',
    label: 'Zip Code',
  },
  {
    name: 'personalInformationTitle',
    isText: true,
    type: 'h6',
    label: 'Personal Information',
  },
  {
    name: 'Mobile',
    type: 'text',
    label: 'Mobile No',
  },
  {
    name: 'Email',
    type: 'text',
    label: 'email',
  },
  {
    name: 'DateBirth',
    type: 'date',
    label: 'Date Of Birth',
    disabled: true,
  },
];

export const formValidationConfig = {
  Email: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  Mobile: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  'Address.ZipCode': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  'Address.State': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  'Address.City': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  'Address.Country.Name': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  'Address.Line2': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  'Address.Line1': { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  EnrolledParentID: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  SID: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  Username: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  Surname: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  Name: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  enrollmentLink: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },

};

export default config;
