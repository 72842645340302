/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
// import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { useMemo, useEffect } from 'react';
// import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import CircleIcon from '@mui/icons-material/Circle';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
// import { useAuthentication } from '../../utils/store';
import { getFromSession } from '../../utils/session';
import { useAuthentication } from '../../utils/store';
import useAuthenticationApi from '../Authentication/api';

export default function Dashboard() {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    // authenticateUser, 
    user, token,
  } = useAuthentication();
  const { getParent } = useAuthenticationApi();
  const signupParent = useMemo(() => getFromSession('signupParent'), []);

  const onSignup = () => {
    navigate('/dashboard/signin');
  };

  const onChoosePass = (type) => {
    sessionStorage.setItem('passType', type);
    navigate('/dashboard/signin');
  };

  useEffect(() => {
    const host = window.location.href;
    const uri = new URL(host);

    console.log('directuri-', uri);

    if (!signupParent && uri.hostname.split('.').length > 2) {
      const [_u] = uri.hostname.split('.');

      console.log('-_username-', _u);

      if (_u) {
        const _fetch = async () => {
          try {
            const res = await getParent(_u);
            sessionStorage.setItem('signupParent', JSON.stringify(res));
          } catch (err) {
            console.log(err);
            toast.error(err);
          }

          navigate('/');
        };

        _fetch();
      }
    }
  }, [signupParent]);

  useEffect(() => {
    if (user && token) {
      navigate('/home');
    }
  }, []);

  if (user && token) {
    return '';
  }

  return (
    <div className="dashboard">

      <div className="header">
        <div className="container-navigation">
          <div className="navigation-left">
            <img
              className="founders-logo"
              alt="memegames"
              src="/assets/MemeGames-logo-fullwhite.png"
            />
          </div>
          <div className="login-container navigation-right">

            <div className="log-buttons ">
              <Button
                onClick={onSignup}
                bordered
                className="google-button"
                size="sm"
              >
                {
                  signupParent ? (
                    <Typography sx={{ textTransform: 'uppercase', fontWeight: '500', alignSelf: 'center' }}>
                      {`Sign Up with: ${signupParent?.firstName} ${signupParent?.lastName} `}
                    </Typography>
                  )
                    : (
                      <Typography sx={{ textTransform: 'uppercase', fontWeight: '500', alignSelf: 'center' }}>
                        Sign In
                      </Typography>
                    )
                }
              </Button>
            </div>
            {
              signupParent && (
                <div className="log-buttons ">
                  <Button
                    onClick={() => {
                      navigate('/login');
                    }}
                    bordered
                    className="google-button"
                    size="sm"
                  >

                    <Typography sx={{ textTransform: 'uppercase', fontWeight: '500', alignSelf: 'center' }}>
                      Sign In
                    </Typography>

                  </Button>
                </div>
              )
            }
          </div>
        </div>
      </div>

      <div className="page">
        <div className="container">
          <div className="body">
            <div className="content-header ">
              <Typography className="content-header-1">
                Welcome to MemeGames Beta:
              </Typography>
              <Typography className="content-header-2">
                The Ultimate MemeCoin Competition!
              </Typography>
            </div>

            <div className="content">
              <div className="content-description">
                <Typography className="content-description-1">
                  Are you ready to dive into the exhilarating world of MemeCoins? Let’s go!
                </Typography>
                <Typography className="content-description-2">
                  Join MemeGames: where strategy, analysis, and quick decision-making can lead you to victory.

                </Typography>
                <Typography className="content-description-3">
                  {` Compete against other gamers and earn points by predicting the performance of trending MemeCoins. Whether you're a seasoned investor 
                or new to the crypto scene, MemeGames offers a fun and engaging way to enhance your market insight.
                Gain knowledge about the MemeCoin market, and learn how to successfully trade!`}
                </Typography>

                <Typography className="content-description-4">
                  Top performers every month will be invited to join MemeCoins on MEGAYACHT meetups across the world, starting Summer 2024!
                </Typography>

              </div>
              <div className="img-wrap">
                <img className="img-1" src="/images/smile.png" alt="" />
              </div>
            </div>

            <Container sx={{ display: 'flex', justifyContent: 'center', my: '20px' }}>
              <Button
                onClick={() => onSignup()}
                className="section-btn"
              >
                <Typography sx={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '22px',
                }}
                >
                  Become a gamer today!
                </Typography>
              </Button>
            </Container>

            {/* // section 1 */}

            <div className="content1">
              <div className="img-wrap">
                <img className="img-2" src="/images/dashboard.png" alt="dashboard-memegames" />
                <img
                  style={{
                    paddingLeft: '43%',
                  }}
                  className="img-1"
                  src="/images/dashboard.png"
                  alt="dashboard-memegames"
                />

              </div>
              <div className="content-description">
                <Typography className="content-description-1">
                  Compete Daily in our MemeCoin Challenge!
                </Typography>
                <Typography className="content-description-2">
                  {`
                  Every day at 12:01 UTC, a new game begins. Two trending MemeCoins are presented, 
                  and it's your job to vote for the one you think will perform better by the end of the day. 
                  Stay on your toes and keep your analysis sharp, because every day brings a new challenge and new opportunities.`}

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  Real-Time Market Data: Access current price, 24-hour volume, market cap, and more.

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`
                                    Social Media Insights: Analyze the latest trends from each coin’s Twitter feed.
                                    `}

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  Historical Performance: Review a performance graph to guide your decisions.

                </Typography>

              </div>

            </div>

            <Container sx={{ display: 'flex', justifyContent: 'center', my: '20px' }}>
              <Button
                onClick={() => onSignup()}
                className="section-btn"
              >
                <Typography sx={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '22px',
                }}
                >
                  Become a gamer today!
                </Typography>
              </Button>
            </Container>

            {/* // section 2 */}

            <div className="content1">

              <div className="img-wrap">
                <img className="img-3" src="/images/arrow-up.png" alt="dashboard-memegames" />
              </div>

              <div className="content-description">
                <Typography className="content-description-1">
                  Cast Your Vote
                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  Vote for the winning MemeCoin
                  {' '}
                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  Time your vote for maximum impact

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  See how your competitors voted
                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  Winners gain daily points

                </Typography>

                <Typography className="content-description-2">
                  {`When you cast your vote for a MemeCoin, the coin’s value at that moment is recorded. At the end of each day’s game, if your MemeCoin wins by outperforming the other coin featured that day, you receive points based on the percent increase in the MemeCoin’s value from the time you voted until the end of that day’s game.
 `}

                </Typography>

              </div>

            </div>

            <Container sx={{ display: 'flex', justifyContent: 'center', my: '20px' }}>
              <Button
                onClick={() => onSignup()}
                className="section-btn"
              >
                <Typography sx={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '22px',
                }}
                >
                  Become a gamer today!
                </Typography>
              </Button>
            </Container>

            {/* // section 3 */}

            <div className="content1 gap-md">

              <div className="content-description">
                <Typography className="content-description-1">
                  Gaming Funds  (GF)
                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  Receive 10,000 GF with each pass

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`Invest your GF after voting
`}

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  Grow your GF to build your power

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  Earn points to move up the leaderboard
                </Typography>

                <Typography className="content-description-2">
                  {`For every 10-day game pass, receive 10,000 Gaming Funds. Use these funds after you vote
       to increase the points received at the end of the daily competition. Winning Gaming Funds are returned to you at the end of each day’s game.
`}

                </Typography>

              </div>

              <div className="img-wrap">
                <img className="img-3" src="/images/bag-gold.jpg" alt="dashboard-memegames" />
              </div>

            </div>

            <Container sx={{ display: 'flex', justifyContent: 'center', my: '20px' }}>
              <Button
                onClick={() => onSignup()}
                className="section-btn"
              >
                <Typography sx={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '22px',
                }}
                >
                  Become a gamer today!
                </Typography>
              </Button>
            </Container>

            {/* // section 4 */}

            <div className="content1 gap-lg">

              <div className="img-wrap">
                <img className="img-3" src="/images/10-20-30.png" alt="dashboard-memegames" />
              </div>

              <div className="content-description">
                <Typography className="content-description-1">
                  Multipliers
                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`    Speed Multiplier: 24x if you vote during the game’s the first hour
`}

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`Performance: 10x if your coin rises at least 10% after you vote 

`}

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  Streak: An additional multiplier for every day of a winning streak

                </Typography>

                <Typography className="content-description-2">
                  {`Multipliers are applied to your point total at the end of each daily game. The Speed Multiplier starts at 24x and goes down by 1x every hour of the game. 
    The Performance Multiplier gives you 1x for each percentage point the price of your coin increases from the time you vote. 
    The Streak Multiplier gives you 1x for every day in a row you vote for the winning MemeCoin.
`}

                </Typography>

              </div>

            </div>

            <Container sx={{ display: 'flex', justifyContent: 'center', my: '20px' }}>
              <Button
                onClick={() => onSignup()}
                className="section-btn"
              >
                <Typography sx={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '22px',
                }}
                >
                  Become a gamer today!
                </Typography>
              </Button>
            </Container>

            {/* // section 5 */}

            <div className="content1 gap-md">

              <div className="img-wrap">
                <img className="img-4" src="/images/yacht.png" alt="dashboard-memegames" />
              </div>

              <div className="content-description">
                <Typography className="content-description-1">
                  Winner Experiences
                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`   Worldwide luxury experiences

`}

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`First experience: A MegaYacht experience in Summer 2024


`}

                </Typography>

                <Typography className="content-description-2">
                  {`Daily winners will be invited to enjoy worldwide luxury experiences. The more you win, the more invites you will receive. Our first experience will be a MemeGames meetup on a MegaYacht later this summer.

`}

                </Typography>

              </div>

            </div>

            <Container sx={{ display: 'flex', justifyContent: 'center', my: '20px' }}>
              <Button
                onClick={() => onSignup()}
                className="section-btn"
              >
                <Typography sx={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '22px',
                }}
                >
                  Become a gamer today!
                </Typography>
              </Button>
            </Container>

            {/* // section 6 */}

            <div className="content1">

              <div className="content-description">
                <Typography className="content-description-1">
                  Ambassadors
                  {' '}
                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`   Daily Pay - Commissions are calculated and paid every day based on a ranking system that increases your percentage payout the more Gamers you recruit play the game.

`}

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`Direct Sales - Receive commissions 
`}
                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`Build Your Team - Bring on other Ambassadors to increase your total volume and receive higher commissions when Gamers they recruit play the game.

`}
                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`Power Team - During Beta, join our Ambassador program to be entered into our power team and accumulate volume from global Ambassador beta sales on a time and date stamp basis.

`}
                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />

                  <span>
                    {' '}
                    Team Builder Support - Daily IG live with
                    <a target="_blank" style={{ marginLeft: '4px' }} href="https://www.instagram.com/memegamesai/" rel="noreferrer"> @MemeGamesai</a>
                  </span>

                </Typography>

              </div>

              <div className="img-wrap">
                <img className="img-3" src="/images/people.jpg" alt="dashboard-memegames" />
              </div>

            </div>

            <Container sx={{ display: 'flex', justifyContent: 'center', my: '20px' }}>
              <Button
                onClick={() => onSignup()}
                className="section-btn"
              >
                <Typography sx={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '22px',
                }}
                >
                  Become a gamer today!
                </Typography>
              </Button>
            </Container>

            {/* // section 7 */}

            <div className="content1 gap-md">

              <div className="img-wrap">
                <Container>
                  <Typography sx={{
                    color: '#ffbf42', fontSize: '43px', textTransform: 'uppercase', width: '200px', margin: 'auto', textAlign: 'center',
                  }}
                  >
                    BETA Gamer Pass $100

                  </Typography>
                  <Typography
                    sx={{
                      color: 'white', fontSize: '10px', textTransform: 'uppercase', margin: 'auto', textAlign: 'center',
                    }}
                  >
                    Instant access with Crypto
                  </Typography>
                </Container>

              </div>

              <div className="content-description">

                <Typography className="content-description-1">
                  Affordable Access
                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`  Become a Gamer - A 10-day pass to join the game is just $100. Gain access to an exciting world of competition, strategy, and education.

`}

                </Typography>

                <Typography className="content-description-3">
                  <CircleIcon className="circle-icon" />
                  {`Launch - We are currently offering Beta memberships for $100,
 which will continue until the start of our MVP, currently scheduled for July 1, 2024, 
 which is when we expect to lauch the initial version of the game. Following the lauch, we intend to add new features based on your feedback.
 
`}
                </Typography>

              </div>

            </div>

            <Container sx={{ display: 'flex', justifyContent: 'center', my: '20px' }}>
              <Button
                onClick={() => onSignup()}
                className="section-btn"
              >
                <Typography sx={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: '22px',
                }}
                >
                  Become a gamer today!
                </Typography>
              </Button>
            </Container>
          </div>

          <div className="footer">
            <img
              className="founders-logo"
              alt="memegames"
              src="/assets/MemeGames-logo-fullwhite.png"
            />
          </div>

          <div className="end">
            <Typography className="text">
              ©
              {new Date().getFullYear()}
              MemeGames.ai. All rights reserved.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
