import { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useApi from '../../../utils/api';
import { defaultFormValues, formValidationConfig } from './config';
import { validateAll } from '../../../components/Form/config';
import { AppContext } from '../../../utils/store/app';

export default function usePackages() {
  const { packages, updatePackage, createPackage } = useContext(AppContext);
  const { authPut, authPost } = useApi();
  const [errors, setErrors] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState();
  const [message, setMessage] = useState();
  const { t } = useTranslation();

  const onPackageClick = (_data) => {
    if (_data) {
      setSelectedPackage(_data);
    } else {
      setSelectedPackage(defaultFormValues);
    }
  };

  const handleModalClose = () => {
    setSelectedPackage();
    setErrors(null);
  };

  const submit = async (data) => {
    try {
      const checkform = validateAll(data, formValidationConfig);
      if (checkform) {
        setErrors(checkform);
      } else if (data._id) {
        const _data = { ...data };
        _data.Points = Number(data.Points || 0);
        _data.Price = Number(data.Price || 0);
        // delete _data.id;
        delete _data._id;
        const updatedPack = await authPut(`/packages/${data.id}`, { data: _data });
        updatePackage(updatedPack);
        toast.success(t('Package Saved Successfully!'));
        handleModalClose();
      } else {
        const res = await authPost('/packages', { data });
        createPackage(res);
        toast.success(t('Package Saved Successfully!'));
        handleModalClose();
      }
    } catch (err) {
      setMessage({ type: 'error', label: err });
      toast.error(t(Object.values(err)[0]));
      setErrors(err);
      if (err.status === 500) {
        toast.error(t('Something went wrong. Please try again later'));
      }

      if (err?.response?.status === 409) {
        toast.error(t('A package with this name already exists!'));
      }
    }
  };

  return {
    submit,
    errors,
    setErrors,
    packages,
    onPackageClick,
    selectedPackage,
    handleModalClose,
    message,
  };
}
