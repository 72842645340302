import clsx from 'clsx';
import PropTypes from 'prop-types';

export default function TextInput({
  type,
  name = '',
  className,
  value = '',
  focused = false,
  placeholder = '',
  size = 'md',
  endAdornment = '',
  startAdornmend = '',
  readOnly = false,
  disabled = false,
  onBlur = () => { },
  onFocus = () => { },
  onChange = () => { },
  error,
  onKeyDown = () => { },
  style = {},
  inputStyle = {},
  rows = 3,

}) {
  return (
    <div style={style} className="form-input-group">
      <div
        className={clsx(
          'form-input text-input flex-row justify-between',
          size,
          disabled && 'disabled',
          className,
        )}
      >
        {startAdornmend}
        {type === 'textarea' ? (
          <textarea
            id={name}
            type={type}
            value={value}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            style={inputStyle}
            className={clsx(
              focused && 'focused',
              size,
              type,
              disabled && 'disabled',
              error && 'error',
            )}
            onChange={(e) => {
              const val = e.target.value;
              onChange(val);
            }}
            readOnly={readOnly}
            disabled={disabled}
            rows={rows}
          />
        ) : (
          <input
            id={name}
            type={type}
            value={value}
            onBlur={onBlur}
            onFocus={(...e) => {
              if (type === 'number') {
                console.log(e);
                e[0].target.select();
              }

              onFocus(e);
            }}
            placeholder={placeholder}
            className={clsx(focused && 'focused', size, disabled && 'disabled', error && 'error')}
            onChange={(e) => {
              const val = e.target.value;
              if (type === 'number') {
                onChange(Number(val || 0));
                return;
              }
              onChange(val);
            }}
            onKeyDown={onKeyDown}
            readOnly={readOnly}
            disabled={disabled}
          />
        )}

        {endAdornment}
      </div>
      {error && <div className="form-input-error">{error}</div>}
    </div>
  );
}

TextInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  size: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
