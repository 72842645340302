/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React from 'react';
import useGenealogy from './context/hook';
import { Provider } from './context';
import CustomTree from './components/CustomTree';
import Banner from '../../../components/Banner';
import Teams from './components/Teams';

const message = {
  id: 0,
  name: 'Attention',
  message: `
  A 1 million dollar Team Volume bonus will be added to your power team for every Ambassador that becomes a paid Beta Gamer by 4:59pm EST on Wednesday, June 19, 2024.
  `,
};

export function GenologyTree() {
  const {
    users, passTypes, loggedUser, loadingUsers,
  } = useGenealogy();
  
  console.log(users);
  console.log('---pass type=---', passTypes);
  
  return (
    <div>
      <Banner data={message} />
      
      <Teams />

      <div className="flex justify-center">
        <CustomTree users={users} passTypes={passTypes} loggedUser={loggedUser} loadingUsers={loadingUsers} />
      </div>
    </div>
  );
}

export default () => (
  <Provider>
    <GenologyTree />
  </Provider>
);
