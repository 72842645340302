import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup as MUIRadioGroup } from '@mui/material';

export default function RadioGroup({
  name = '',
  value = '',
  options = [],
  focused = false,
  placeholder = '',
  onChange = () => { },
  size = 'md',
  disabled = false,
  error,
  setErrors,
  defaultValue,
  sx = {},
}) {
  return (
    <MUIRadioGroup
      sx={sx}
      id={name}
      value={value || defaultValue}
      placeholder={placeholder}
      className={clsx('flex-1 select', focused && 'focused', size)}
      onChange={(e) => {
        onChange(e.target.value);
        setErrors(null);
      }}
      disabled={disabled}
      defaultValue={defaultValue}
    >
      {error && <div className="form-input-error">{error}</div>}
      {options.map((o) => (
        <FormControlLabel label={o.label} control={<Radio />} value={o.value} key={o.value} />
      ))}
    </MUIRadioGroup>
  );
}

RadioGroup.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
