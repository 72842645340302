import { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';
import CustomDrawer from './components/Drawer';
import AppBar from './components/AppBar';
import { APP_BAR_HEIGHT } from '../../constants';
import { useStyle } from '../../utils/store';
import { AuthenticationContext } from '../../utils/store/authentication';
// TODO remove, this demo shouldn't need to reset the theme.

export default function AppLayout() {
  const { user } = useContext(AuthenticationContext);
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const defaultTheme = useStyle();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {user && (
        <>
          <AppBar toggleDrawer={toggleDrawer} open={open} />
          <CustomDrawer toggleDrawer={toggleDrawer} open={open} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => (theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.background.default),
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
              px: '3em',
              // [defaultTheme.muiTheme.breakpoints.between('md', 'xs')]: { ml: '72px' },
              pb: 7,
              // md: { ml: '72px' },
              // sm: { ml: '72px' },
              ml: {
                sm: '72px', md: '72px', xs: '72px', xl: '0px', lg: '0px',
              },
            }}
          >
            <Toolbar
              sx={{
                height: `${APP_BAR_HEIGHT}px`,
              }}
            />

            <Outlet />
          </Box>
        </>
      )}

    </Box>
  );
}
