/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Icon from '../../../../../components/Icon';

export const colConfig = ({
  onDelete = () => { }, onEdit = () => { }, onView = () => { }, onSimulate = () => { },
}) => ({
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',

  cellClassName: () => 'first-column ',
  renderCell: (params) => {
    if (params.field === 'actions') {
      return (
        <div className="flex">
          {/* Edit Icon */}
          <IconButton color="inherit" onClick={() => onEdit(params)}>
            <EditIcon
              style={{ cursor: 'pointer' }}

            />
          </IconButton>

          {/* Delete Icon */}
          <IconButton color="inherit" onClick={() => onDelete(params)}>
            <DeleteIcon
              style={{ cursor: 'pointer' }}
            />
          </IconButton>

          {/* Simulate Icon */}
          <IconButton color="inherit" onClick={() => onSimulate(params.row.id)}>
            <Icon name="Spy" width={24} height={24} />
          </IconButton>
        </div>
      );
    }

    if (params.field === 'Name') {
      return (
        <div className="cursor-pointer" onClick={() => onView(params)}>
          {params.value}
          {' '}
          {params.row.Surname}
        </div>
      );
    }

    return params;
  },
});

export const defaultFormData = {
  name: '',
};

export const userFormConfig = [
  {
    name: 'myAccountTitle',
    isText: true,
    type: 'h6',
    label: 'Account Data',
  },
  {
    name: 'enrollmentLink',
    type: 'text',
    label: 'Enrollment Link',
  },
  {
    name: 'name',
    type: 'text',
    label: 'Name',
  },
  {
    name: 'surname',
    type: 'text',
    label: 'Surname',
  },
  {
    name: 'username',
    type: 'text',
    label: 'Username',
  },
  {
    name: 'memberID',
    type: 'text',
    label: 'Member ID',
  },
  {
    name: 'enrolledParentId',
    type: 'text',
    label: 'Enrolled Parent ID',
  },
  {
    name: 'serviceLevel',
    type: 'text',
    label: 'Service Level',
  },
  {
    name: 'addressInfoTitle',
    isText: true,
    type: 'h6',
    label: 'Address Info',
  },
  {
    name: 'addressLine1',
    type: 'text',
    label: 'Address Line 1',
  },
  {
    name: 'addressLine2',
    type: 'text',
    label: 'Address Line 2',
  },
  {
    name: 'country',
    type: 'text',
    label: 'Country',
  },
  {
    name: 'city',
    type: 'text',
    label: 'City',
  },
  {
    name: 'state',
    type: 'text',
    label: 'State',
  },
  {
    name: 'zipCode',
    type: 'text',
    label: 'Zip Code',
  },
  {
    name: 'personalInformationTitle',
    isText: true,
    type: 'h6',
    label: 'Personal Information',
  },
  {
    name: 'mobileNo',
    type: 'text',
    label: 'Mobile No',
  },
  {
    name: 'email',
    type: 'text',
    label: 'email',
  },
  {
    name: 'birthday',
    type: 'date',
    label: 'Date Of Birth',
  },
  {
    name: 'title',
    isText: true,
    type: 'h6',
    label: 'Change Password',
  },
  {
    name: 'oldPassword',
    type: 'password',
    label: 'Old Password',
  },
  {
    name: 'newPassword',
    type: 'password',
    label: 'New Password',
  },
  {
    name: 'retypePassword',
    type: 'password',
    label: 'Retype Password',
  },
];

export default {};
