import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import config from './config';
import Form from '../../../../components/Form';
import useRenewMembership from './hook';

export default function RenewPasses({ refresh }) {
  const { t } = useTranslation();
  const [setActions] = useState({ setFormValue: () => { } });
  const {
    errors, submitRenew, renewedMsg, setRenewedMsg,
  } = useRenewMembership();
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (renewedMsg) {
      setAlertOpen(true);
      refresh();
    }
  }, [renewedMsg, alertOpen, setAlertOpen]);

  return (
    <div>
      <h2 className="mb-4">{t('Activate Gaming Pass')}</h2>
      {renewedMsg && (
        <Collapse in={alertOpen}>
          <Alert
            action={(
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(false);
                  setRenewedMsg(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
            sx={{ mb: 2 }}
          >
            {renewedMsg}
          </Alert>
        </Collapse>
      )}

      <Form
        size="sm"
        errors={errors}
        config={config}
        onSubmit={submitRenew}
        setActions={setActions}
        submitLabel="One Click Renew"
        buttonType="submit"
        buttonStyle={{ minWidth: '182px' }}
      />
    </div>
  );
}
