import { useContext } from 'react';
import { AppContext } from '../../../../../../../utils/store/app';

export const formConfig = [
  {
    name: 'passType',
    label: 'Choose the pass type',
    type: 'select',
    options: [],
  },
  {
    name: 'username',
    type: 'text',
    label: 'Recipient Username/Email',
  },
  {
    name: 'amountOfPasses',
    type: 'number',
    label: 'Amount of passes',
  },
  {
    name: 'renewOnTransfer',
    label: 'Auto renew the user after transfer?',
    type: 'muiCheckbox',
    checked: false,
  },
  {
    name: 'transferable',
    label: 'Allow the user to transfer this pass/passes to other persons?',
    type: 'muiCheckbox',
    checked: false,
  },
];

export const getFormConfig = () => {
  const { passTypes } = useContext(AppContext);
  const opts = passTypes?.map((p) => ({ label: `${p?.name} (${p?.total || 0})`, value: p?.id }));
  formConfig[0].options = opts;
  return formConfig;
};

export const defaultValue = { amountOfPasses: 1 };

export const formValidationConfig = {
  username: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  amountOfPasses: {
    isEmpty: false,
    type: 'Number',
    message: 'Please do not leave this field empty!',
  },
  passType: { isEmpty: false, type: 'String', message: 'Please chose a pass type!' },
};
