/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
// import AppleSignin from 'react-apple-signin-auth';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useMemo } from 'react';
import useAuthenticationApi from '../api';
import Button from '../../../components/Button';
import Layout from '../../../components/AuthLayout';
import { useAuthentication } from '../../../utils/store';
import { useNonAuthApi } from '../../../utils/api';
import { getFromSession } from '../../../utils/session';

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authenticateUser } = useAuthentication();
  const {
    getGoogleUser,
    logInGoogle,
    // logInApple
  } = useAuthenticationApi();
  const { nonAuthGet } = useNonAuthApi();
  const signupParent = useMemo(() => getFromSession('signupParent'), []);

  const onSignInGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const user = await getGoogleUser(tokenResponse.access_token);
      const _user = {
        givenName: user.given_name,
        familyName: user.family_name,
        picture: user.picture,
        email: user.email,
        emailVerified: user.email_verified,
        locale: user.locale,
        sub: user.sub,
        name: user.name,
      };

      try {
        const loginRes = await logInGoogle({ user: _user });
        console.log(loginRes);
        // check if the payment process is done
        if (loginRes.user.signupStep === 'finished') {
          authenticateUser(loginRes.user, loginRes.token);
          const _params = sessionStorage.getItem('circle-redirect');

          if (_params?.length > 0) {
            window.location.href = `${process.env.REACT_APP_CIRCLE_OAUTH}${_params}`;
          } else {
            navigate('/');
          }
        }
      } catch (err) {
        if (err === 'Unauthorized') {
          navigate('/dashboard/registration/new');
          sessionStorage.setItem('registration', JSON.stringify({ ..._user, source: 'google' }));
        }
        if (err?.statusCode === 402) {
          const eUser = err.user;
          const enrolledParentID = await nonAuthGet(`dashboard/enroll/${eUser.EnrolledParentID}`);
          sessionStorage.setItem('SignupSID', eUser.SID);
          sessionStorage.setItem('signupUser', JSON.stringify(eUser));
          sessionStorage.setItem('token', err.token);
          sessionStorage.setItem('signupParent', JSON.stringify(enrolledParentID));
          sessionStorage.setItem(
            'registration',
            JSON.stringify({
              givenName: eUser.Name,
              familyName: eUser.Surname,
              email: eUser.Email,
              source: 'google',
            }),
          );
          navigate('/dashboard/enroll-now/pay');
        }
      }
    },
  });

  const onSignInEmail = () => {
    if (signupParent) {
      navigate('/signup/form');
    } else {
      navigate('/login');
    }
  };

  return (
    <Layout>
      <div className="login-page">
        <div className="flex-row justify-center mb-7">
          <img alt="logo-black" src="/assets/logo_black.svg" />
        </div>

        <h1 className="mb-13">{t(`SIGN ${signupParent ? 'UP' : 'IN'}`)}</h1>

        <Button
          onClick={() => {
            // if (signupParent) {
            onSignInGoogle();
            // } else {
            //  navigate('/dashboard/registration/new');
            // }
          }}
          icon={<img src="/assets/google.svg" alt="google" />}
          bordered
          className="mb-3"
        >
          Google
        </Button>

        <Button
          onClick={onSignInEmail}
          icon={<AlternateEmailIcon fontSize="large" />}
          bordered
          className="mb-3 mt-3"
        >
          Email
        </Button>
      </div>
    </Layout>
  );
}
