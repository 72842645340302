import { Container, Grid, Typography } from '@mui/material';
import copy from 'copy-to-clipboard';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import TextInput from '../../../components/Inputs/TextInput';
import { Context } from '../context';
import Button from '../../../components/Button';
import Banner from '../../../components/Banner';

const message = {
  id: 0,
  name: 'Attention',
  message: `
  A 1 million dollar Team Volume bonus will be added to your power team for every Ambassador that becomes a paid Beta Gamer by 4:59pm EST on Wednesday, June 19, 2024.
  `,
};

export default function ReferalLink() {
  const { user } = useContext(Context);

  return (
    <>
      <Banner data={message} />

      <Container sx={{ display: 'flex', justifyContent: 'center', mb: 10 }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          {' '}
          {`${user.Name || ''} ${user.Surname || ''}`}
          {' '}
        </Typography>
      </Container>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Typography sx={{ py: 'auto', my: 'auto', fontWeight: 500 }}>Referal Link:</Typography>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <TextInput
            readOnly
            size="sm"
            value={`${user.Username}.${process.env.REACT_APP_BASE_DOMAIN}`}
            endAdornment={(
              <Button
                onClick={() => {
                  toast.success('Link Copied!');
                  copy(`${user.Username}.${process.env.REACT_APP_BASE_DOMAIN}`);
                }}
                className="py-auto my-auto mr-5"
                bordered
                size="sm"
              >
                <span className="no-wrap">COPY LINK</span>
              </Button>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
