import { Container, Typography } from '@mui/material';
import { useContext } from 'react';
import Table from '../../../components/Table';
import { Context } from '../context';
import Layout from '../../../components/CardLayout';
import Icon from '../../../components/Icon';

export default function SalesTable() {
  const {
    sales,
    salesColumns,
    t,
  } = useContext(Context);

  if (sales?.length) {
    return (
      <Container
        sx={{
          px: { xl: 0 },
        }}
      >
        <Table columns={salesColumns} rows={sales} className="table" />
      </Container>
    );
  }

  return (
    <Layout maxWidth="xl" sx={{ mt: 0 }} style={{ height: '100%', opacity: 0.7 }}>
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%',
      }}
      >
        <Icon
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', width: '45px', height: '45px',
          }}
          name="ShoppingBag"
        />
        <Typography sx={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 1,
        }}
        >
          {t('No other sales yet')}
        </Typography>
      </div>

    </Layout>
  );
}
