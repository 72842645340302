import Icon from '../components/Icon';

// eslint-disable-next-line no-confusing-arrow
const getColor = (focused, theme) => (!focused ? 'rgba(255,255,255, 0.7)' : theme.PRIMARY_COLOR);

const drawerItems = [
  {
    id: 0,
    label: 'Home',
    path: '/',
    roles: ['all'],
    // isAffiliate: true,
    // isProduct: true,
    icon: (focused, theme) => (
      <Icon name="Home" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  // {
  //  id: 9,
  //  label: 'Community',
  //  isAffiliate: true,
  //  isProduct: false,
  //  path: 'https://portal.trec.global',
  //  type: 'external',
  //  roles: ['all'],
  //  icon: (focused, theme) => (
  //    <Icon name="People" color={getColor(focused, theme)} width={16} height={16} />
  //  ),
  // },
  // {
  //  id: 10,
  //  label: 'Technology',
  //  isAffiliate: true,
  //  isProduct: false,
  //  path: 'https://portal.trec.global/c/technology-academy/',
  //  type: 'external',
  //  roles: ['all'],
  //  icon: (focused, theme) => (
  //    <Icon name="Technology" color={getColor(focused, theme)} width={16} height={16} />
  //  ),
  // },

  // {
  //  id: 11,
  //  label: 'Real Estate',
  //  isAffiliate: true,
  //  isProduct: false,
  //  path: 'https://portal.trec.global/c/real-estate-academy/',
  //  type: 'external',
  //  roles: ['all'],
  //  icon: (focused, theme) => (
  //    <Icon name="RealEstate" color={getColor(focused, theme)} width={16} height={16} />
  //  ),
  // },

  // {
  //  id: 12,
  //  label: 'Crypto',
  //  isAffiliate: true,
  //  isProduct: false,
  //  path: 'https://portal.trec.global/c/crypto-academy/',
  //  type: 'external',
  //  roles: ['all'],
  //  icon: (focused, theme) => (
  //    <Icon name="Bitcoin" color={getColor(focused, theme)} width={16} height={16} />
  //  ),
  // },
  {
    id: 1,
    label: 'Admin',
    isAffiliate: true,
    isProduct: true,
    path: '/admin/members',
    roles: ['admin', 'superadmin', 'owner', 'root'],
    icon: (focused, theme) => (
      <Icon name="Admin" color={getColor(focused, theme)} width={16} height={16} />
    ),
    children: [
      {
        id: 11,
        label: 'Member Admin',
        path: '/admin/members',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
    
      {
        id: 12,
        label: 'Passes',
        path: '/admin/passes',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: 13,
        label: 'Pass Types',
        path: '/admin/pass-types',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: 14,
        label: 'Packages',
        path: '/admin/packages',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
      {
        id: 15,
        label: 'Ranks',
        path: '/admin/ranks',
        roles: ['admin', 'superadmin', 'owner', 'root'],
      },
    ],
  },
  // {
  //  id: 7,
  //  label: 'Wallets',
  //  path: '/wallets',
  //  roles: ['all'],
  //  isAffiliate: false,
  //  isProduct: true,
  //  icon: (focused, theme) => (
  //    <Icon name="Wallets" color={getColor(focused, theme)} width={16} height={16} />
  //  ),
  // },
  {
    id: 2,
    label: 'Profile',
    path: '/profile',
    roles: ['all'],
    // isAffiliate: true,
    // isProduct: true,
    icon: (focused, theme) => (
      <Icon name="Profile" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
  
  {
    id: 99,
    label: 'Sales',
    isAffiliate: false,
    isProduct: true,
    path: '/sales/list',
    roles: ['all'],

    icon: (focused, theme) => (
      <Icon name="Genealogy" color={getColor(focused, theme)} width={16} height={16} />
    ),
    children: [
      {
        id: 31,
        label: 'List',
        path: '/sales/list',
        roles: ['all'],
      },
      {
        id: 32,
        label: 'Genealogy',
        path: '/sales/tree',
        roles: ['all'],
      },
      
    ],
  },

  {
    id: 4,
    label: 'Memberships',
    path: '/memberships',
    isAffiliate: false,
    isProduct: true,
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="Memberships" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },

  {
    id: 5,
    label: 'Support',
    // isAffiliate: true,
    // isProduct: true,
    path: '/',
    roles: ['all'],
    icon: (focused, theme) => (
      <Icon name="Support" color={getColor(focused, theme)} width={16} height={16} />
    ),
  },
];

export default drawerItems;
