import { Container, Grid } from '@mui/material';
import { useContext, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/CardLayout';
import AppLoader from '../../components/AppLoader';
import { Provider, Context } from './context';
import ReferalLink from './components/referalLink';
import ComunityHome from './components/comunityHome';
// import AffiliateUpdates from './components/affiliateUpdates';
import SalesTable from './components/salesTable';
import Volume from './components/volume';
import Wallets from './components/wallets';
import Events from './components/events';
import News from './components/news';
import AskAI from './components/askAI';
import RenewBanner from './components/renewBanner';
import MemecoinVideo from './components/memecoinVideo';
import MemeCoinsPool from './components/memecoins';

export function Home() {
  const { user, showProduct } = useContext(Context);
  const navigate = useNavigate();
  
  if (!user) {
    navigate('/landing');
  }

  const canView = user?.Permissions?.IsAdmin || user?.isAffiliate;
  const canView2 = !(user.isAffiliate && !user.isProduct);
  const showBanner = user.isProduct && user.isAffiliate && !user.Monetized && moment().startOf('day').isAfter(user.DateExpiring);

  return (
    <Container sx={{ mt: !canView2 ? '40px' : '' }} maxWidth="xl">
      {
        showBanner && (
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mb: 4 }}>
            <RenewBanner />
          </Grid>
        )
      }
    
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mb: 4 }}>
        <MemeCoinsPool />
      </Grid>

      <Grid spacing={4} container className="home">

        {showProduct && (
          <>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container spacing={4}>
              {
                (canView2 && !showBanner) && (
                  <Grid item>
                    <ComunityHome />
                  </Grid>
                )
              }
              {
                (canView2 && !showBanner) && (
                  <Grid item>
                    <News />
                  </Grid>
                )
              }
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} container spacing={4}>
              {
                (canView2 && !showBanner) && (
                  <Grid item>
                    <Events />
                  </Grid>
                )
              }
              {
                (canView2 && !showBanner) && (
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <MemecoinVideo />
                  </Grid>
                )
              }

              {
                (canView2 && !showBanner) && (
                  <Grid item className="ask-ai-grid">
                    <AskAI />
                  </Grid>
                )
              }
            </Grid>
          </>
        )}

        {
          canView && (
            <>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Layout sx={{ maxHeight: '100%' }} maxWidth="100%">
                  <Grid spacing={3} container>
                    <Grid sx={{}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <ReferalLink />
                    </Grid>
                    {/* <Grid sx={{}} item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Volume />
                    </Grid> */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <SalesTable />
                    </Grid>

                  </Grid>
                </Layout>
              </Grid>

              {/* <Grid item xl={12} lg={12} md={12} sm={12}>
                <Wallets />
              </Grid> */}

              {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <AffiliateUpdates />
              </Grid> */}
            </>
          )
        }

      </Grid>
    </Container>

  );
}

export default () => (
  <Provider>
    <Home />
  </Provider>
);
