import dayjs from 'dayjs';
import { Grid, Tooltip, Typography } from '@mui/material';
import Icon from '../../../components/Icon';

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  sortable: true,
  renderCell: ({ value }) => value,
};

export const columnsConfig = (t, user, onUserClick) => [
  {
    field: 'displayName',
    headerName: t('NAME'),
    minWidth: 500,
    renderCell: ({ row }) => (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"

      >
        <Grid item xs={12}>
          <Typography sx={{ cursor: 'pointer' }} onClick={() => onUserClick(row)}>
            {row.displayName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" style={{ color: '#919191' }}>
            {row.username}
            {' '}
            {`(${row.userId})`}
          </Typography>
        </Grid>
      </Grid>
    ), // return `${value}`;

    headerClassName: 'super-app-theme--header',
    headerAlign: 'left',
    sortable: true,
    cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor first-column' : 'first-column'),

  },

  {
    field: 'dateLastRenewed',
    headerName: t('LAST RENEWED'),
    minWidth: 430,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('DD MMM YYYY'),
    cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor' : ''),

  },
  {
    field: 'rank',
    minWidth: 130,
    headerName: `${t('RANK')}`,
    cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor' : ''),

    ...defaultConfig,
  },
  {
    field: 'icons',
    flex: 1,
    headerName: '',
    align: 'right',
    columnAlign: 'right',
    type: 'actions',
    headerAlign: 'right',
    cellClassName: (_style) => (_style.row.comId !== user.SID ? 'non-sponsor' : ''),
    renderCell: (_data) => (
      <div className="flex justify-between items-center ">
        <div style={{ width: '30px', maxWidth: '30px' }}>
          {
            _data.row.training ? (
              <Tooltip title={t('Training: A User that is one of the first 2 users brought in by their sponsor')}>
                <div>
                  <Icon height="20px" width="20px" name="UserConfig" />
                </div>
              </Tooltip>
            ) : ''
          }
        </div>
        <div style={{ width: '30px', maxWidth: '30px' }}>
          {
            _data.row.status === 'inactive' ? (
              <Tooltip id="tooltup" title={t('Inactive: A User that has not paid their monthly or yearly membership fee')}>
                <div>
                  <Icon style={{ marginBottom: '3px' }} height="17px" width="17px" name="UserX" />
                </div>
              </Tooltip>
            ) : ''
          }
        </div>
        <div style={{ width: '30px', maxWidth: '30px' }}>
          {
            _data.row.qualified ? '' : (
              <Tooltip title={t('Unqualified: A User that has not yet signed up at least 2 other people')}>
                <div>
                  <Icon height="25px" width="25px" name="UserLupa" />
                </div>
              </Tooltip>
            )
          }
        </div>
      </div>
    ),
  },
];

export default columnsConfig;
