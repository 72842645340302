import { Container, Typography } from '@mui/material';
import { useContext } from 'react';
import Layout from '../../../components/CardLayout';
import { Context } from '../context';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import VoteCoin from './VoteCoin';
import ComponentLoader from '../../../components/ComponentLoader';

export default function MemeCoinsPool() {
  const {
    chosenMemeCoin, onVoteMemeCoin, loadingCoins,
    memeCoinsList, openMemeConfirmModal, setOpenMemeConfirmModal, onChooseMemeCoin,
  } = useContext(Context);

  return (
    <>
      <div className="flex gap-2 flex-wrap">
        <Layout className="flex-1" maxWidth="100%" cardMainClassName="h-full flex flex-column">
          <Typography sx={{
            color: 'var(--primary-color)', fontSize: '19px', fontWeight: 'bold', mb: 2, display: 'flex', justifyContent: 'center',
          }}
          >
            Today&apos;s Matchup
          </Typography>

          <div className="flex gap-1 justify-center flex-1">
            {loadingCoins ? <ComponentLoader /> : memeCoinsList?.map((i) => <VoteCoin coin={i} />)}
          </div>

          {(chosenMemeCoin && !loadingCoins) ? (
            <Button className="mt-4" onClick={() => onChooseMemeCoin({})}>
              View Votes
            </Button>
          ) : null}

        </Layout>

        <Layout className="flex-1" maxWidth="100%" cardMainClassName="h-full flex flex-column">
          <Typography sx={{
            color: 'var(--primary-color)', fontSize: '19px', fontWeight: 'bold', mb: 2, display: 'flex', justifyContent: 'center',
          }}
          >
            Yesterday&apos;s Closeout
          </Typography>

          <div className="flex gap-1 justify-center flex-1">
            {loadingCoins ? (
              <ComponentLoader />
            ) : memeCoinsList?.map((i) => <VoteCoin coin={i} isYesterday />)}
          </div>
        </Layout>
      </div>

      {openMemeConfirmModal ? (
        <Modal
          title={chosenMemeCoin ? 'Votes' : 'Vote for MemeCoin'}
          open={openMemeConfirmModal}
          handleClose={() => { setOpenMemeConfirmModal(); }}
          width="700px"
        >
          <Container>
            <div className="flex gap-1 justify-center flex-1 mb-2">
              {loadingCoins ? (
                <ComponentLoader />
              ) : memeCoinsList?.map((i) => <VoteCoin coin={i} isVote />)}
            </div>

            {chosenMemeCoin ? null : (
              <Typography sx={{ fontSize: '16px' }}>
                Are you sure you want to vote for
                {' '}
                <span style={{ color: 'var(--primary-color)' }}>{openMemeConfirmModal?.name}</span>
                {' '}
                ?
              </Typography>
            )}

            <div style={{
              display: 'flex', gap: '10px', marginTop: '15px', marginBottom: '15px',
            }}
            >
              {chosenMemeCoin ? null : (
                <Button onClick={() => onVoteMemeCoin()} style={{ width: '100%' }}>
                  Vote
                </Button>
              )}

              <Button onClick={() => { setOpenMemeConfirmModal(); }} style={{ width: '100%' }} bordered>
                Cancel
              </Button>
            </div>
          </Container>

        </Modal>
      ) : null}

      {/* <div className="flex gap-2 flex-wrap mb-8">
        <Layout className="flex-1" maxWidth="100%">
          <Typography sx={{
            color: 'var(--primary-color)', 
            fontSize: '19px', fontWeight: 'bold', mb: 2, display: 'flex', justifyContent: 'center',
          }}
          >
            Your Points
          </Typography>
        </Layout>

        <Layout className="flex-1" maxWidth="100%">
          <Typography sx={{
            color: 'var(--primary-color)',
             fontSize: '19px', fontWeight: 'bold', mb: 2, display: 'flex', justifyContent: 'center',
          }}
          >
            Community Points
          </Typography>
        </Layout>
      </div> */}

      {/* <News /> */}
    </>
  );
}
