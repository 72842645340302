import { Grid, Pagination, Typography } from '@mui/material';
import Table from '../../../../../components/Table';
import { userFormConfig } from './config';
import Form from '../../../../../components/Form';
import Modal from '../../../../../components/Modal';
import Layout from '../../../../../components/CardLayout';
import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/Inputs/TextInput';
import useMembersListApi from './api';

export default function List() {
  const {
    columns,
    rows,
    searchClick,
    setSearchText,
    searchText,
    formData,
    onAdd,
    onModalClose,
    paginationData,
    getUsers,
  } = useMembersListApi();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchClick();
    }
  };
  return (
    <>
      <Layout className="members-list" maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xl={10} lg={10} md={10} sm={9} xs={12}>
            <TextInput
              size="sm"
              placeholder="What would you like to search for?"
              value={searchText}
              onChange={(e) => {
                console.log(e);
                setSearchText(e);
              }}
              onKeyDown={handleKeyDown}
              endAdornment={(
                <Button onClick={searchClick} className="py-auto my-auto mr-5" bordered size="sm">
                  <span className="no-wrap">Search</span>
                </Button>
              )}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={3} xs={12} className="flex justify-end">
            <Button className="my-auto py-auto" size="sm" onClick={onAdd}>
              Add +
            </Button>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Table
              columns={columns}
              rows={rows}
              className="table"
              hideFooterPagination
              hideFooter
            />
            {paginationData && (
              <div className="flex justify-end mt-4">
                <Typography sx={{ mt: 0.5 }}>
                  {`Total of: ${paginationData.totalUsers} users`}
                </Typography>
                <Pagination
                  count={paginationData?.total}
                  page={paginationData?.page}
                  onChange={(val, page) => {
                    getUsers(page);
                  }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Layout>
      <Modal open={formData} handleClose={onModalClose} title="Add User">
        <Form
          config={userFormConfig}
          size="sm"
          className="horizontal-alignment"
          submitLabel="Save Personal Information"
        />
      </Modal>
    </>
  );
}
