import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme, width }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },

  '& .MuiModal-backdrop': {
    backdropFilter: 'blur(1.5px)',
  },

  '& .MuiDialog-paper': {
    background:
      'linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #141518',
    maxWidth: width,
    width,
  },
}));

export default function Modal({
  handleClose, open = false, children, title = '', width = '50%',
}) {
  return (
    <BootstrapDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      width={width}
      id="custom-modal"
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          px: 5,
          display: 'flex',
          justifyContent: 'center',
          justifyItems: 'center',
          fontSize: '26px',
          fontWeight: 600,
        }}
        id="customized-dialog-title"
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ m: 3 }}>{children}</DialogContent>
    </BootstrapDialog>
  );
}
