/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import PassTypes from '../../../../../Authentication/PayNow/components/PassTypes';
import BuyButton from '../../../../../Authentication/PayNow/components/BuyButton';
import Modal from '../../../../../../components/Modal';
import useApi from '../../../../../../utils/api';
import NumberInput from '../../../../../../components/Inputs/NumberInput';
// import StripePay from '../../../../../Authentication/PayNow/components/StripePay';
// import { useAuthentication } from '../../../../../../utils/store';

export default function PurchasePassTypeModal({
  data,
  selectedPassType,
  setPurchasePassesData,
  fetchPassTypes,
  fetchPasses,
}) {
  const { passType } = data;
  const { t } = useTranslation();
  const [loading, setLoading] = useState();
  const { authPost, authDelete } = useApi();
  const [amount, setAmount] = useState('');
  const [error, setError] = useState();
  const formRef = useRef(null);
  // const [step, setStep] = useState(1);
  // const { user } = useAuthentication();
  // const [clientSecret, setClientSecret] = useState();

  const handleBuyModalClose = async () => {
    setLoading(true);
    const _puid = sessionStorage.getItem('_puid');
    if (_puid) {
      try {
        await authDelete(`/payments/passes/remove/${_puid}`);
      } catch (err) {
        toast.error(t(err));
      }
      sessionStorage.removeItem('_puid');
    }
    setLoading(false);
    setPurchasePassesData();
  };

  const onBuy = async () => {
    if (!amount) {
      setError('The amount of passes should not be empty or 0');
    } else {
      setLoading(true);
      if (data.priceTotal === 0) {
        try {
          const res = await authPost('/payments/passes/free', { data: { ...data, units: amount } });
          if (res) {
            fetchPassTypes();
            fetchPasses();
          }
          toast.success(t('You have succefully renewed your membership!'));
          setLoading(false);
          handleBuyModalClose();
        } catch (err) {
          toast.error(t(err));
        }
      } else {
        const form = formRef.current;
        form.submit();
      }
    }
  };

  // const getClientSecret = async () => {
  //   try {
  //     const res = await authPost('/stripe/create-payment-intent', {
  //       data: {
  //         passTypeId: data.passType.id, user, amountOfPasses: amount, buyPasses: true,
  //       },
  //     });
  //     setClientSecret(res.client_secret);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const onPurchase = async () => {
    onBuy(); // crypto payment

    // stripe payment
    // setStep(2);
    // getClientSecret(); 
  };

  return (
    <Modal
      title={t('Purchase Passes')}
      width="25%"
      open={!!data}
      handleClose={handleBuyModalClose}
    >
      {/* {
        step === 1 ? ( */}
      <Grid container spacing={3}>

        <PassTypes
          packages={[passType]}
          loading={loading}
          formDescription="You don't have enough passes available to transfer. Do you want to buy some?"
          setSelectedPackage={() => { }}
          selectedPackage={selectedPassType}
        />
        <Grid container item>
          <NumberInput
            size="sm"
            style={{ minWidth: '100%' }}
            className="full-width"
            name="amountOfPasses"
            value={String(amount) || ''}
            placeholder="Enter the amount of passes"
            onBlur={() => { }}
            onFocus={() => { }}
            onChange={(v) => {
              setError();
              setAmount(v);
            }}
            error={error}
          />
        </Grid>
        <Grid container item>
          <form method="POST" onSubmit={() => console.log('submitted')} ref={formRef} action={process.env.REACT_APP_BTC_PAY}>
            <input type="hidden" name="orderId" value={data.PUID} />
            <input type="hidden" name="storeId" value={process.env.REACT_APP_BTC_STORE_ID} />
            <input type="hidden" name="checkoutDesc" value={data.Description} />
            <input type="hidden" name="serverIpn" value={data?.serverIpn} />
            <input type="hidden" name="browserRedirect" value={data?.redirect} />
            <input type="hidden" name="price" value={data.priceTotal} />
            <input type="hidden" name="currency" value="USD" />
          </form>
        </Grid>
        <BuyButton
          title="Buy Now"
          disabled={loading}
          type="submit"
          onSubmitBitcoin={onPurchase}
        />
      </Grid>
      {/* //   )

      //     : (
      //       <StripePay
      //         setStep={setStep}
      //         signupUser={user}
      //         location="memberships"
      //         clientSecret={clientSecret}
      //       />
      //     )
      // } */}

    </Modal>
  );
}
