/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
import {
  useCallback, useState, useEffect, useContext, useMemo, 
} from 'react';
import { useTranslation } from 'react-i18next';
import useApi from '../../../../utils/api';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import { AppContext } from '../../../../utils/store/app';
import columnsConfig from '../config';

const PAGE_SIZE = 25;

export default function useGenealogy() {
  const { authGet } = useApi();
  const [users, setUsers] = useState([]);
  const [genealogyData, setGenealogyData] = useState();
  const { user } = useContext(AuthenticationContext);
  const { passTypes } = useContext(AppContext);
  const { t } = useTranslation();
  const [usersTotal, setusersTotal] = useState(0);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [paginationData, setPaginationData] = useState();
  const [usersPage, setUsersPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({ sortBy: 'default' });
  const [sortModel, setSortModel] = useState([
    {
      field: 'displayName',
      sort: 'desc',

    },
  ]);
  
  const fetchUsers = useCallback(
    async (page = 1) => {
      setLoadingUsers(true);
 
      try {
        // orderBy[name]=1
        const _sort = `orderBy[${sortModel ? sortModel[0]?.field : 'Name'}]`;
        const res = await authGet('/genealogy/users', {
          params: {
            page, perPage: PAGE_SIZE, search: searchText, [_sort]: sortModel ? sortModel[0]?.sort === 'desc' ? '-1' : '1' : '-1', 
          },
        });
        console.log('genealogy ---', res);
        const tree = res.data;
        setUsers(tree);
        setGenealogyData(res);
        setusersTotal(res?.total || 0);
        setLoadingUsers(false);
        setUsersPage(page);
      } catch (err) {
        console.log(err);
        setLoadingUsers(false);
      }
    },
    [user, sortModel, searchText],
  );

  const searchClick = useCallback((e) => {
    fetchUsers();
  }, [searchText, paginationData]);

  useEffect(() => {
    fetchUsers();
  }, [user, sortModel]);

  const [openModal, setOpenModal] = useState();
  const onUserClick = (_data) => {
    setOpenModal(_data);
  };

  const onModalClose = () => {
    setOpenModal();
  };

  const columns = useMemo(() => columnsConfig(t, user, onUserClick), [t, user, onUserClick]);
  const totalPages = useMemo(() => Math.ceil(usersTotal / PAGE_SIZE), [usersTotal]);

  const handleFilterChange = (e, type) => {
    const tmp = { ...filters };
    tmp[type] = e;
    setFilters(tmp);
    console.log(e);
  };

  return {
    passTypes,
    users,
    genealogyData,
    fetchUsers,
    columns,
    totalPages,
    usersTotal,
    t,
    loadingUsers,
    usersPage,
    handleFilterChange,
    filters,
    sortModel,
    setSortModel,
    searchClick,
    searchText,
    setSearchText,
    setPaginationData,
    paginationData,
    openModal,
    onModalClose,
  };
}
