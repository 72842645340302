import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../../../../components/CardLayout';

export default function PassTypeCard({ data, onPassTypeClick = () => { } }) {
  return (
    <Layout>
      <Typography
        sx={{
          fontWeight: 600,
          color: 'var(--primary-color)',
          fontSize: '16px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {data.name}
        <EditIcon
          sx={{ cursor: 'pointer' }}
          color="primary"
          onClick={() => {
            onPassTypeClick(data);
          }}
        />
      </Typography>
      <div className="divider" />
      <Typography sx={{ opacity: 0.5 }}>{data.description}</Typography>
      <Typography>
        Price :
        <span className="pl-2">
          $
          {Number(data.price || 0).toLocaleString('en-us')}
        </span>
      </Typography>
      <Typography>
        Available Days :
        <span className="pl-2">{data.days}</span>
      </Typography>
      {data?.points >= 1 && (
        <Typography>
          Points :
          <span className="pl-2">{data.points}</span>
        </Typography>
      )}
    </Layout>
  );
}
