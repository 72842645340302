import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import AuthLayout from '../../../components/AuthLayout';
import Form from '../../../components/Form';
import { validateAll } from '../../../components/Form/config';
import useAuthenticationApi from '../api';
import { useAuthentication } from '../../../utils/store';
import { useNonAuthApi } from '../../../utils/api';
import { getFromSession } from '../../../utils/session';

const formConfig = [
  {
    name: 'username',
    type: 'text',
    label: 'Username',
    placeholder: 'Username',
    format: (item) => item.trim(),

  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    format: (item) => item.trim(),
  },
  {
    name: 'forgotPassword',
    type: 'link',
    label: 'Forgot Password?',
    path: '/reset-password',
  },
];

const formValidationConfig = {
  username: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  password: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
};

const defaultData = {
  username: '',
  password: '',
};

export default function LoginForm() {
  const { authenticateUser } = useAuthentication();
  const { logInAdmin } = useAuthenticationApi();
  const { nonAuthGet } = useNonAuthApi();
  const [formConfiguration] = useState(formConfig);
  const [currentData, setCurrentData] = useState([]);
  const { t } = useTranslation();
  const getFormConfig = () => formConfiguration;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const signupParent = useMemo(() => getFromSession('signupParent'), []);

  const onSubmit = async (data) => {
    const _check = validateAll(data, formValidationConfig);
    if (_check) { setErrors(_check); } else {
      setLoading(true);
      try {
        const res = await logInAdmin(data);

        authenticateUser(res?.user, res?.token);
        if (res?.user?.SID && res.token) {
          // window.location.href = '/';
          const _params = sessionStorage.getItem('circle-redirect');

          if (!res?.user?.emailVerified && res.user.SID !== 'admin') {
            // if (process.env.NODE_ENV !== 'development') {
            //   navigate('/verify-email');
            // } else {
            navigate('/home');
            // }
          } else if (_params?.length > 0) {
            window.location.href = `${process.env.REACT_APP_CIRCLE_OAUTH}${_params}`;
          } else {
            navigate('/home');
          }
        }
      } catch (err) {
        console.log('--login error--', err);
        let msg = err;
        if (err === 'Unauthorized') {
          msg = 'Invalid credentials';
        } else if (typeof err === 'string' && err?.toLowerCase() === 'invalid credentials') {
          toast.error(err);
        } else if (!err.user.emailVerified) {
          const eUser = err.user;
          const enrolledParentID = await nonAuthGet(`dashboard/enroll/${eUser.EnrolledParentID}`);
          sessionStorage.setItem('SignupSID', eUser.SID);
          sessionStorage.setItem('signupUser', JSON.stringify(eUser));
          sessionStorage.setItem('token', err.token);
          sessionStorage.setItem('signupParent', JSON.stringify(enrolledParentID));
          sessionStorage.setItem(
            'registration',
            JSON.stringify({
              givenName: eUser.Name,
              familyName: eUser.Surname,
              email: eUser.Email,
              source: 'google',
            }),
          );
          // if (process.env.NODE_ENV !== 'development') {
          //   navigate('/verify-email');
          // }
        } else if (err?.statusCode === 402) {
          try {
            const eUser = err.user;
            const enrolledParentID = await nonAuthGet(`dashboard/enroll/${eUser.EnrolledParentID}`);
            sessionStorage.setItem('SignupSID', eUser.SID);
            sessionStorage.setItem('signupUser', JSON.stringify(eUser));
            sessionStorage.setItem('token', err.token);
            sessionStorage.setItem('signupParent', JSON.stringify(enrolledParentID));
            sessionStorage.setItem(
              'registration',
              JSON.stringify({
                givenName: eUser.Name,
                familyName: eUser.Surname,
                email: eUser.Email,
                source: 'google',
              }),
            );
            console.log(err.statusCode);
            navigate('/dashboard/enroll-now/pay');
          } catch (err1) {
            toast.error('Something went wrong. Please try again later!');
          }
        } else {
          toast.error('Something went wrong. Please try again later!');
        }
        // setErrors(msg);
      }
      setLoading(false);
    }
  };

  const onSignUpClick = () => {
    if (signupParent) {
      navigate('/signup/form');
    } else {
      navigate('/dashboard/registration/new');
    }
  };
  useEffect(() => {
    setErrors();
  }, [currentData]);

  return (
    <AuthLayout>

      <Typography sx={{
        display: 'flex', color: 'var(--primary-color) !important', justifyContent: 'center', fontSize: '27px', mb: 5, fontWeight: 600,
      }}
      >
        {t(' LOGIN')}
      </Typography>

      <Form
        defaultFormValue={defaultData}
        size="md"
        config={getFormConfig(t)}
        submitLabel="Login"
        onSubmit={onSubmit}
        errors={errors}
        formLoading={loading}
        setCurrentData={setCurrentData}
        buttonType="submit"
      />

      <Container sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Typography sx={{ fontSize: '14px' }} color="white">
          {t("Don't have an account?")}
        </Typography>
        <Typography
          onClick={() => onSignUpClick()}
          sx={{
            fontSize: '14px', color: 'var(--primary-color) !important', fontWeight: 600, ml: 1, cursor: 'pointer',
          }}
        >
          {t('Sign Up')}
        </Typography>
      </Container>

    </AuthLayout>
  );
}
