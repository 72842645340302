/* eslint-disable no-unused-vars */
import {
  useContext, useState, useCallback,
} from 'react'; 
import { AuthenticationContext } from '../../../../utils/store/authentication'; 
import useApi from '../../../../utils/api';
 
export default function useRenewMembership() {
  const { user, updateUser } = useContext(AuthenticationContext);
  const [errors, setErrors] = useState(null);
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [renewedMsg, setRenewedMsg] = useState('');
  const { authPost } = useApi();

  const submitRenew = useCallback(async (data) => {
    setIsLoading(true);
    try {
      if (!data?.pass) {
        setErrors({ pass: 'Pass Key is required' });
      } else {
        const result = await authPost('/passes/consume', { data: { token: data.pass } });
        setRenewedMsg(result.message);
        setErrors(null);
      }
      setIsLoading(false);
    } catch (err) {
      if (err === 'Invalid pass token') {
        setErrors({ pass: err });
      } else {
        setErrors({ pass: 'An error occurred. Please try again later!' });
      }
      setIsLoading(false);
    }
  }, [userData]);

  return {
    userData, submitRenew, isLoading, setIsLoading, errors, renewedMsg, setRenewedMsg,
  };
}
