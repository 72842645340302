import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Layout from '../../../components/CardLayout';
import PaymentRenewModal from '../../Profile/components/Welcome/components/PaymentRenewModal';
import RenewMembershipModal from '../../Profile/components/Welcome/components/RenewMembershipModal';
import useApi from '../../../utils/api';
import { useAuthentication } from '../../../utils/store';

export default function RenewBanner() {
  const { t } = useTranslation();
  const { authGet } = useApi();
  const { user } = useAuthentication();
  const [renewPaymentData, setRenewPaymentData] = useState();
  const [renewMembershipData, setRenewMembershipData] = useState();
  const [selectedPackage, setSelectedPackage] = useState();
 
  useEffect(() => {
    localStorage.removeItem('renew-account');
  }, []);

  const onRenew = async () => {
    try {
      // 1. Check if has passes available
      const availablePasses = await authGet('passes/available');
      // 2. Buy one with bitcoin
      if (availablePasses.length >= 1) {
        setRenewMembershipData({ user, availablePasses });
      } else {
        setRenewPaymentData({ user });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePaymentModalClose = () => {
    setRenewPaymentData();
  };

  const handleRenewModalClose = () => {
    setRenewMembershipData();
  };

  return (
      
    <Layout maxWidth="100%">
      <Typography sx={{ display: 'flex' }}>
        Your membership is currently inactive.
        <Typography
          onClick={() => {
            onRenew();
          }}
          color="primary"
          sx={{ color: 'var(--primary-color) !important', marginLeft: '5px', cursor: 'pointer' }}
        > 
          {' '}
          {t('Click here to renew!')}
          {' '}
        </Typography>
      </Typography>

      {renewPaymentData && (
        <PaymentRenewModal
          handleModalClose={handlePaymentModalClose}
          data={renewPaymentData}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
          location=""
        />
      )}

      {renewMembershipData && (
        <RenewMembershipModal
          handleModalClose={handleRenewModalClose}
          data={renewMembershipData}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
        />
      )}
   
    </Layout>
  );
}
