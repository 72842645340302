/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import clsx from 'clsx';
import { Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import { Navigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../Button';
import Select from '../Inputs/Select';
import TextInput from '../Inputs/TextInput';
import DateInput from '../Inputs/DatePicker';
import AjaxSelect from '../Inputs/AjaxSelect';
import RadioGroup from '../Inputs/RadioGroup';
import AjaxRadioGroup from '../Inputs/AjaxRadioGroup';
import CustomCheckbox from '../Inputs/Checkbox';
import MoneyInput from '../Inputs/MoneyInput';
import NumberInput from '../Inputs/NumberInput';
import PhoneInput from '../Inputs/PhoneInput';
import PasswordInput from '../Inputs/Password';

export default function Form({
  config = [],
  size = 'md',
  className = '',
  onSubmit = () => { },
  defaultFormValue,
  submitLabel = 'Submit',
  submitClassName = '',
  setActions = () => { },
  errors,
  setErrors,
  formLoading = false,
  buttonType,
  buttonStyle,
  onChangeSelect = () => { },
  onClickSelectOption = () => { },
  setCurrentData = () => { },
  clearAfterSubmit = false,
}) {
  const { t } = useTranslation();
  const [focusedInputs, setFocusedInputs] = useState([]);
  const [formValue, setFormValue] = useState({});

  useEffect(() => {
    if (typeof setActions === 'function') {
      setActions({ setFormValue });
    }
  }, []);

  useEffect(() => {
    setCurrentData(formValue);
  }, [formValue, setCurrentData]);

  useEffect(() => {
    const tmp = { ...defaultFormValue };
    setFormValue(tmp || {});
  }, [defaultFormValue]);

  const onChangeFormItem = useCallback(
    async (val, key, item) => {
      // setFormValue((prev) => ({ ...prev, [key]: val }));
      const _val = item?.format ? item.format(val) : val;
      const newState = _.cloneDeep(formValue);
      const tmp = _.set(newState, key, _val);
      setFormValue(tmp);
      onChangeSelect(tmp);
    },
    [formValue],
  );

  const onInputFocus = useCallback((key) => {
    setFocusedInputs((prev) => [...prev, key]);
  }, []);

  const onInputBlur = useCallback((key) => {
    setFocusedInputs((prev) => prev.filter((v) => v !== key));
  }, []);

  const renderInput = (item) => {
    switch (item.type) {
      case 'text':
      case 'textarea':
      case 'email':
        return (
          <TextInput
            size={size}
            name={item.name}
            type={item.type}
            value={String(_.get(formValue, item.name) || '')}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name, item)}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );

      case 'password': return (
        <PasswordInput
          size={size}
          name={item.name}
          type={item.type}
          value={String(_.get(formValue, item.name) || '')}
          placeholder={item.placeholder}
          onBlur={() => onInputBlur(item.name)}
          onFocus={() => onInputFocus(item.name)}
          focused={focusedInputs.includes(item.name)}
          onChange={(v) => onChangeFormItem(v, item.name, item)}
          disabled={item.disabled}
          error={errors && errors[item?.name]}
        />
      );

      case 'phone':
        return (
          <PhoneInput
            size={size}
            name={item.name}
            type={item.type}
            value={_.get(formValue, item.name)}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => {
              onChangeFormItem(v, item.name);
            }}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'number':
        return (
          <NumberInput
            size={size}
            name={item.name}
            type={item.type}
            value={String(_.get(formValue, item.name) === 0 ? 0 : _.get(formValue, item.name) || '')}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'money':
        return (
          <MoneyInput
            size={size}
            name={item.name}
            value={String(_.get(formValue, item.name)) || 0}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'date':
        return (
          <DateInput
            size={size}
            value={_.get(formValue, item.name)}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'ajaxSelect':
        return (
          <AjaxSelect
            endPoint={item.endPoint}
            name={item.name}
            mapper={item.mapper}
            value={_.get(formValue, item.name)}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            size={size}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'select':
        return (
          <Select
            name={item.name}
            options={item.options}
            value={_.get(formValue, item.name)}
            placeholder={item.placeholder}
            onBlur={() => onInputBlur(item.name)}
            onFocus={() => onInputFocus(item.name)}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            size={size}
            onClickSelectOption={onClickSelectOption}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'ajaxRadioGroup':
        return (
          <AjaxRadioGroup
            endPoint={item.endPoint}
            name={item.name}
            mapper={item.mapper}
            value={_.get(formValue, item.name)}
            placeholder={item.placeholder}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            size={size}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
            setErrors={setErrors}
          />
        );
      case 'radioGroup':
        return (
          <RadioGroup
            name={item.name}
            options={item.options}
            value={_.get(formValue, item.name)}
            defaultValue={item?.defaultValue}
            placeholder={item.placeholder}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            size={size}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
            setErrors={setErrors}
          />
        );
      case 'checkbox':
        return (
          <CustomCheckbox
            name={item.name}
            options={item.options}
            value={_.get(formValue, item.name) || false}
            placeholder={item.placeholder}
            focused={focusedInputs.includes(item.name)}
            onChange={(v) => onChangeFormItem(v, item.name)}
            size={size}
            disabled={item.disabled}
            error={errors && errors[item?.name]}
          />
        );
      case 'muiCheckbox':
        return (
          <FormControlLabel
            control={(
              <Checkbox
                sx={{ color: 'white', fontSize: '12px !important' }}
                checked={item?.active || !!_.get(formValue, item.name)}
                aria-label={item?.label}
                defaultChecked={_.get(formValue, item.name) || !!item?.checked}
                onChange={(e) => {
                  setFormValue({ ...formValue, [item.name]: e.target.checked });
                  // onChangeFormItem(v, item.name)
                }}
              />
            )}
            sx={{
              color: 'white', fontSize: '12px !important', alignItems: 'flex-start',
            }}
            label={(
              <Typography sx={{
                fontSize: '12px', fontWeight: 500, letterSpacing: '1.2px', mt: '11px',
              }}
              >
                {t(item?.label)}
              </Typography>
            )}
            required={!!item?.required}
            disabled={!!item?.disabled || formLoading}
          />

        );
      case 'boolean':
        return (
          <FormControlLabel
            control={(
              <Checkbox
                checked={!!formValue[item.name]}
                aria-label={item?.label}
                onChange={(e) => {
                  setFormValue({ ...formValue, [item.name]: e.target.checked });
                  // onChangeFormItem(v, item.name)
                }}
              />
            )}
            label={item?.label}
            required={!!item?.required}
            disabled={!!item?.disabled || formLoading}
          />

        );

      default:
        return null;
    }
  };

  const renderItem = (item) => {
    if (item.isText) {
      return (
        <div key={item.name} className={clsx('form-group form-group-text mb-3')}>
          <Typography component="span" variant={item.type} sx={{ fontWeight: 600 }}>
            {t(item.label)}

          </Typography>
          <Typography component="span" variant={item.type} sx={{ fontWeight: 600 }}>
            {t(item?.subLabel || '')}

          </Typography>
        </div>
      );
    }

    if (item.type === 'link') {
      return (
        <div
          key={item.name}
          className={clsx(
            'form-group',
            focusedInputs.includes(item.name) && 'focused',
            item.formGroupType || 'default',
          )}
        >

          <Typography
            onClick={() => { window.location.href = item.path; }}
            color="primary"
            sx={{
              display: 'flex', justifyContent: 'center', ':hover': { fontWeight: 600 }, cursor: 'pointer',
            }}
          >
            {item.label}
            {' '}

          </Typography>

        </div>
      );
    }
    return (
      <div
        key={item.name}
        className={clsx(
          'form-group',
          focusedInputs.includes(item.name) && 'focused',
          item.formGroupType || 'default',
        )}
      >
        {(item.type !== 'muiCheckbox' && item.type !== 'boolean') && (
          <div className={`label flex-row justify-between ${item.labelClassName} ${item?.subLabel ? 'no-margin' : 'yes-margin'}`}>
            <label htmlFor={item.name}>{item.label}</label>
            {!!item.header && <div>{item.header}</div>}
          </div>
        )}
        {
          item.subLabel && <label className="sublabel" htmlFor={item.subLabel}>{item.subLabel}</label>

        }

        {item.type && renderInput(item)}
      </div>
    );
  };

  return (
    <div
      className={clsx('form', className, size)}
      style={{ pointerEvents: formLoading ? 'none' : 'all', position: 'relative' }}
    >
      {formValue ? config?.map(renderItem) : config?.map(renderItem)}

      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Button
          type={buttonType}
          size="md"
          className={`${submitClassName}`}
          disabled={formLoading}
          onClick={() => {
            if (clearAfterSubmit) {
              setFormValue(defaultFormValue);
            }
            onSubmit(formValue);
          }}
          style={buttonStyle}
        >
          {t(submitLabel)}
          {formLoading && <ClipLoader sx={{ ml: 2 }} size={13} color="black" />}
        </Button>
      </Grid>

    </div>
  );
}
