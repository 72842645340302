import { useTranslation } from 'react-i18next';
import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { colConfig, defaultFormData } from './config';
import useApi from '../../../../../utils/api';
import useAuthenticaitonStore from '../../../../../utils/store/authentication/hook';

const PAGE_SIZE = 25;

const useMembersListApi = () => {
  const { authenticateUser } = useAuthenticaitonStore();
  const [formData, setFormData] = useState();
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authGet } = useApi();
  const [rows, setRows] = useState([]);
  const [paginationData, setPaginationData] = useState();

  // #region Users Table

  const getUsers = useCallback(
    async (page = 1) => {
      try {
        const res = await authGet('/users', {
          params: { page, perPage: PAGE_SIZE, search: searchText },
        });
        setRows(
          res?.data?.map((item) => ({
            id: item.SID,
            ...item,
          })),
        );
        setPaginationData({
          totalUsers: res.total,
          total: Math.ceil(res.total / PAGE_SIZE),
          page,
        });
      } catch (err) {
        console.log(err);
      }
    },
    [authGet, paginationData, searchText],
  );

  useEffect(() => {
    getUsers();
  }, []);

  const onDelete = () => {};
  
  const onEdit = (params) => {
    // setFormData(params.row);
    navigate(`/admin/members/${params.row.id}`);
  };
  
  const onView = (params) => {
    navigate(`/admin/members/${params.row.id}`);
  };
  
  const onAdd = useCallback(() => {
    setFormData(defaultFormData);
  }, [formData]);

  const onSimulate = useCallback(async (sid) => {
    try {
      const res = await authGet(`/auth/impersonate?sid=${sid}`);
      authenticateUser(res.user, res.token);
      sessionStorage.setItem('_imp', 1);
      // navigate('/');
      window.location.href = '/';
    } catch (err) {
      console.log(err);
    }
  }, []);
  
  const onModalClose = () => {
    setFormData(null);
  };

  const columns = [
    {
      field: 'SID',
      headerName: t('Member ID'),
      width: 150,
      ...colConfig,
    },
    {
      field: 'Name',
      headerName: t('NAME'),
      width: 250,
      ...colConfig({ onView }),
    },

    {
      field: 'Username',
      headerName: t('Username'),
      width: 200,
      ...colConfig,
    },
    {
      field: 'rank',
      headerName: t('Rank'),
      width: 100,
      ...colConfig,
    },
    // {
    //   field: 'ParentID',
    //   headerName: t('Parent Id'),
    //   width: 150,
    //   ...colConfig,
    // },
    {
      field: 'EnrolledParentID',
      headerName: t('Sponsor'),
      width: 120,
      ...colConfig,
    },
    {
      field: 'Status',
      headerName: t('Status'),
      width: 100,
      ...colConfig,
    },
    {
      field: 'Email',
      headerName: t('Email'),
      width: 250,
      ...colConfig,
    },
    // {
    //   field: 'Mobile',
    //   headerName: t('Mobile'),
    //   width: 250,
    //   ...colConfig,
    // },
    // {
    //   field: 'dateOfBirth',
    //   headerName: t('Date Of Birth'),
    //   width: 250,
    //   ...colConfig,
    // },
    // {
    //   field: 'country',
    //   headerName: t('Country'),
    //   width: 250,
    //   ...colConfig,
    // },
    // {
    //   field: 'joinDate',
    //   headerName: t('Join Date'),
    //   width: 250,
    //   ...colConfig,
    // },
    // {
    //   field: 'expirationDate',
    //   headerName: t('Expiration Date'),
    //   width: 250,
    //   ...colConfig,
    // },
    // {
    //   field: 'lastLoginDate',
    //   headerName: t('Last Login Date'),
    //   width: 250,
    //   ...colConfig,
    // },
    {
      field: 'actions',
      headerName: '',
      width: 150,
      ...colConfig({ onDelete, onEdit, onSimulate }),
    },
  ];

  // #endregion
  
  // #region Search

  const searchClick = useCallback(() => {
    getUsers(paginationData.page);
  }, [searchText, paginationData]);

  return {
    rows,
    columns,
    searchText,
    setSearchText,
    searchClick,
    formData,
    onAdd,
    onModalClose,
    paginationData,
    getUsers,
    onSimulate,
  };
};

export default useMembersListApi;
