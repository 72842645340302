import { useContext } from 'react';
import { AppContext } from '../../../../utils/store/app';

const formConfig = [
  {
    name: 'package',
    type: 'radioGroup',
    label: 'Choose a Package',
    options: [],
  },
  // {
  //  name: 'paymentMethod',
  //  type: 'radioGroup',
  //  label: 'Choose Payment Method',
  //  options: [{ label: 'Crypto', value: 'crypto' }],
  // },
];

export const getFormConfig = () => {
  const { packages } = useContext(AppContext);
  const opts = packages.map((p) => ({ label: `${p.name} ($${p.Price}) ${p.numberOfPasses} passes`, value: p.id }));
  formConfig[0].options = opts;
  formConfig[0].defaultValue = opts[0]?.value;
  return formConfig;
};

export const defaultValue = { };

export default formConfig;
