import Bell from './configs/Bell';
import User from './configs/User';
import Home from './configs/Home';
import Admin from './configs/Admin';
import Profile from './configs/Profile';
import Support from './configs/Support';
import ListItems from './configs/ListItems';
import Genealogy from './configs/Genealogy';
import Memberships from './configs/Memberships';
import DoubleChevronLeft from './configs/DoubleChevronLeft';
import Spy from './configs/Spy';
import Spy2 from './configs/Spy2';
import Wallet from './configs/Wallet';
import UserX from './configs/users-x';
import UserLupa from './configs/users-lupa';
import UserConfig from './configs/users-config';
import Money from './configs/money';
import ShoppingBag from './configs/ShoppingBag';
import People from './configs/people';
import Technology from './configs/technology';
import RealEstate from './configs/realEstate';
import Bitcoin from './configs/bitcoin';
import Ranks from './configs/Ranks';
import GraduationHat from './configs/graduationHat';
import TrecLogo from './configs/TrecLogo';

export default {
  Bell,
  Home,
  User,
  Admin,
  Profile,
  Support,
  ListItems,
  Genealogy,
  Memberships,
  DoubleChevronLeft,
  Spy,
  Spy2,
  Wallets: Wallet,
  UserX,
  UserLupa,
  UserConfig,
  Money,
  ShoppingBag,
  People,
  Technology,
  RealEstate,
  Bitcoin,
  Ranks,
  GraduationHat,
  TrecLogo,
};
