/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Container, Typography } from '@mui/material';
import { useMemo, useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import Layout from '../../../components/AuthLayout';
import { getFromSession } from '../../../utils/session';
import Button from '../../../components/Button';

export default function ThankYouPage() {
  const registrationData = useMemo(() => getFromSession('registration'));
  const paidSignupUser = useMemo(() => getFromSession('paidSignupUser'));
  const signupUser = useMemo(() => getFromSession('signupUser'));
  const jwtToken = useMemo(() => getFromSession('token', 'string'));
  const [supportLink, setSupportLink] = useState('');
  const navigate = useNavigate();

  const fetchHelp = async () => {
    const day = moment().format('DD');
    const month = moment().format('MM');
    const { SID, Email } = supportLink;
    const sharedsecret = process.env.REACT_APP_HELPDESK_SECRET;
    const helpdeskUrl = process.env.REACT_APP_HELPDESK_URL;
    const preHash = SID + Email + sharedsecret + day + month;
    const hash = CryptoJS.MD5(preHash).toString(CryptoJS.enc.Hex);
    const tmp = `${helpdeskUrl}/helpdesk/User/AutoLogin?username=${SID}&email=${Email}&userHash=${hash}`;
    setSupportLink(tmp);
  };

  useEffect(() => {
    fetchHelp();
  }, []);

  useEffect(() => {
    if (!registrationData && !paidSignupUser) {
      navigate('/');
    }
  }, []);

  return (
    <Layout style={{ padding: '40px' }}>
      <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div>
          <Typography
            color="white"
            sx={{
              fontSize: '24px',
              opacity: '100% !important',
            }}
          >
            Your registration is complete!
          </Typography>
        </div>

        <Typography
          color="white"
          sx={{
            fontSize: '14px',
            opacity: '100% !important',
          }}
          k
        >
          Thank you for joining the community!
        </Typography>

        <Button
          className="mt-6"
          onClick={() => {
            localStorage.setItem('token', jwtToken);
            const url = `${process.env.REACT_APP_BACKOFFICE}?_u=${jwtToken}`;
            console.log('urlpp---', url);
            window.location.href = url;
          }}
        >
          Enter Now
        </Button>
        {signupUser?.isAffiliate && (
          <>
            <Typography
              color="white"
              sx={{
                fontSize: '14px',
                opacity: '100% !important',
                my: 4,
              }}
            >
              {/* {`Don't forget, as an ambassador you can get rewarded up to 30% by signing
                  up other community members!`} */}

              Your personal signup link is as follows:
              <br />
              <span
                onClick={() => {
                  toast.success('Link Copied!');
                  copy(`${signupUser?.Username || paidSignupUser?.Username}.${process.env.REACT_APP_BASE_DOMAIN}`);
                }}
                style={{ cursor: 'pointer' }}
              >
                <Typography
                  variant="span"
                  sx={{
                    fontSize: '18px',
                  }}
                  color="primary"
                >
                  {`${signupUser?.Username || paidSignupUser?.Username}.${process.env.REACT_APP_BASE_DOMAIN}`}
                </Typography>
                <ContentCopyIcon
                  sx={{ mx: 1 }}
                  fontSize="12px"
                  color="primary"
                />
              </span>
            </Typography>

            {/* <Typography
              color="white"
              sx={{
                fontSize: '14px',
                opacity: '100% !important',
              }}
            >
              {`Any revenues generated will be deposited into your wallet in your backoffice account.
              Click here to go directly to purchasing monthly passes and view your wallet: `}
              <Typography
                variant="span"
                color="primary"
                onClick={() => {
                  localStorage.setItem('token', jwtToken);

                  const url = `${process.env.REACT_APP_BACKOFFICE}/wallets?_u=${jwtToken}`;
                  window.location.href = url;
                }}
                sx={{
                  fontSize: '14px',
                  opacity: '100% !important',
                  cursor: 'pointer',
                }}
              >
                My Wallet
              </Typography>
            </Typography> */}

            <Typography
              color="primary"
              onClick={() => {
                localStorage.setItem('token', jwtToken);

                const url = `${process.env.REACT_APP_BACKOFFICE}/memberships?_u=${jwtToken}`;
                window.location.href = url;
              }}
              sx={{
                fontSize: '14px',
                opacity: '100% !important',
                cursor: 'pointer',
                mt: 2,
              }}
            >
              Buy more Memberships
            </Typography>

            <Typography
              variant="span"
              color="white"
              sx={{
                fontSize: '14px',
                opacity: '100% !important',
                my: 4,
              }}
            >
              {'Any issues with signup? Please '}
              <a
                style={{ textDecoration: 'underline', color: '#D8A878' }}
                href={supportLink}
                target="_blank"
                rel="noreferrer"
              >
                contact support
              </a>

            </Typography>
          </>
        )}

      </Container>
    </Layout>
  );
}
