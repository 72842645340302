import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Pagination, Grid } from '@mui/material';
import { columnsConfig } from './config';
import Table from '../../../../components/Table';
import { PAGE_SIZE } from '../../api';
import Button from '../../../../components/Button';
import TransferPassesModal from './components/transfer/TransferPassesModal';
import Select from '../../../../components/Inputs/Select';

export default function Passes({
  data = [], loading, passesTotal, fetchPasses, passesPage,
  passTypes, fetchTransfers, setPassesLoading, passesTotalAvailable,
}) {
  const { t } = useTranslation();

  const columns = useMemo(() => columnsConfig(t), [t]);
  const totalPages = useMemo(() => Math.ceil(passesTotal / PAGE_SIZE), [passesTotal]);
  const [transferData, setTransferData] = useState();
  const [errors, setErrors] = useState();
  const [filters, setFilters] = useState({ page: passesPage });
  const [selectedFilters, setSelectedFilters] = useState(1);

  const handleFilterChange = (selectedFilter) => {
    setPassesLoading(true);
    if (selectedFilter?.used === 'default' || selectedFilter?.used === 'all') {
      selectedFilter.used = undefined;
    }
    if (selectedFilter?.passTypeId === 'default' || selectedFilter?.passTypeId === 'all') {
      selectedFilter.passTypeId = undefined;
    }

    setFilters({ ...filters, ...selectedFilter });
    fetchPasses({ page: 1, ...filters, ...selectedFilter });

    if (!Object.keys(filters).includes('used')) {
      setSelectedFilters(selectedFilters + 1);
    }

    setPassesLoading(false);
  };

  const handleModalClose = async () => {
    try {
      setTransferData(null);
      setErrors(null);
    } catch (err) {
      console.log(err);
    }
  };

  const onTransferPasses = () => {
    setTransferData({
      passTypes,
      passesTotal,
    });
  };

  return (
    <div>
      <div className="flex flex-row justify-between mb-4">
        <h2>
          {t('Passes')}
          <span style={{ fontSize: '14px', marginLeft: '12px' }}>{`(${passesTotalAvailable} Available)`}</span>
        </h2>
        <div className="flex flex-row">

          <Grid item container xs={6} justifyContent="flex-end" alignItems="center">
            <h3>
              {t('Filters')}
              :
            </h3>
            <Select
              className="transfrs-filter-select"
              placeholder="Used"
              size="sm"
              value={filters?.used || 'default'}
              onChange={(v) => {
                handleFilterChange({ used: v });
              }}
              options={[
                { value: 'all', label: 'All' },
                { value: 'true', label: 'Used' },
                { value: 'false', label: 'Not Used' },
              ]}
            />
          </Grid>

          <Select
            className="transfrs-filter-select"
            placeholder="Pass Type"
            size="sm"
            value={filters?.passTypeId || 'default'}
            onChange={(v) => handleFilterChange({ passTypeId: v })}
            options={passTypes.map((p) => ({ value: p.id, label: p.name }))}
          />
          <Button
            size="sm"
            className="ml-4"
            onClick={(e) => onTransferPasses(e)}
          >
            {t('Transfer Passes')}
          </Button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Table loading={loading} columns={columns} rows={data} className="table mb-4" />
          <div className="flex justify-end">
            <Pagination
              count={totalPages || 0}
              page={passesPage || 1}
              onChange={(val, page) => {
                fetchPasses({ ...filters, page });
              }}
            />
          </div>
        </div>
      )}
      {transferData && (
        <TransferPassesModal
          errors={errors}
          submit=""
          data={transferData}
          handleModalClose={handleModalClose}
          fetchPasses={fetchPasses}
          fetchTransfers={fetchTransfers}
        />
      )}
    </div>
  );
}
