/* eslint-disable prefer-destructuring */
import { useContext } from 'react';
import { AppContext } from '../../../../utils/store/app';

export const formConfig = [
  {
    name: 'passType',
    type: 'radioGroup',
    label: 'Choose Pass Type',
    options: [],
    defaultValue: '',
  },
 
  {
    name: 'amountOfPasses',
    type: 'number',
    label: 'Amount of Passes',
  },
];

export const getFormConfig = () => {
  const { passTypes } = useContext(AppContext);
  const opts = passTypes.filter((p) => p.active).map((p) => ({ label: `${p.name} ($${p.price})`, value: p.id }));
  formConfig[0].options = opts;
  formConfig[0].defaultValue = opts[0]?.value;
  return formConfig;
};

export const defaultValue = { paymentMethod: 'credit', amountOfPasses: 1 };

export default formConfig;
