import { Divider, Typography } from '@mui/material';

export default function AffiliateLink({ affiliateOnlyClick }) {
  return (
    <>
      <Divider sx={{ mt: 5, mb: 3 }} />
      <Typography
        onClick={affiliateOnlyClick}
        sx={{
          ':hover': { fontWeight: '500', textDecoration: 'underline' }, textAlign: 'center', color: 'var(--primary-color) !important', cursor: 'pointer', fontStyle: 'italic',
        }}
        color="primary"
      >
        I wish to continue as an Ambassador only. I will not pay for a beta gamer pass
        and will not have access to the Beta Game buildout phase.
      </Typography>
    </>
  );
}
