import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/Modal';
import Form from '../../../../components/Form';
import { getFormConfig } from '../config';

export default function PassModal({
  errors,
  data,
  handleModalClose,
  submit,
  message,
  formLoading,
}) {
  const { t } = useTranslation();

  return (
    <Modal title="Generate Pass" width="35%" open={!!data} handleClose={handleModalClose}>
      {message && (
        <Alert sx={{ mb: '15px' }} className={message?.type} severity={message?.type}>
          {t(message?.label)}
        </Alert>
      )}

      <Form
        errors={errors}
        defaultFormValue={data}
        config={getFormConfig()}
        size="sm"
        className=""
        submitLabel="Submit"
        onSubmit={submit}
        formLoading={formLoading}
        buttonStyle={{ minWidth: '182px' }}
        buttonType="button"
      />
    </Modal>
  );
}
