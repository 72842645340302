import { Container } from '@mui/material';
import Button from '../../../../../components/Button';

export default function Teams() {
  return (
    <Container sx={{
      display: 'flex', justifyContent: 'end', gap: 3, mb: 5, 
    }}
    >
      <Button disabled color="brown">
        Team 1
      </Button>
    
      <Button disabled color="dark-red">
        Team 2
      </Button>
      <Button disabled color="purple">
        Team 3
      </Button>
    </Container>
  );
}
