import { createContext } from 'react';
import useGenealogy from './hook';

export const Context = createContext({
  passTypes: [],
  users: [],
  genealogyData: [],
  getUsersForTree: () => {},
  t: () => {},
  fetchUsers: () => {},
  columns: [],
  totalPages: 0,
  loadingUsers: false,
  usersPage: 0,
  handleFilterChange: () => {},
  sortModel: [],
  setSortModel: () => {},
  searchClick: () => {},
  searchText: '',
  setSearchText: () => {},
  setPaginationData: () => {},
  paginationData: 0,
  openModal: null,
  onModalClose: () => {},
});

export const Provider = ({ children }) => {
  const value = useGenealogy();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
